import { Component, ViewEncapsulation, OnInit, Inject} from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging'
import { AuthService } from './auth.service';
import { SujjestSessionadapterService } from './sujjest-sessionadapter.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
  message;
  //private swPush: SwPush
  readonly VAPID_PUBLIC_KEY = "BLzOYjYkzurOe6GAh7JRzv2D9WhiC2GWSQkpBlnnrFyfDspv89dUl3fx1EZE6C2o4IYccNws8lDaJjJXekKYMhY"

  constructor(
    @Inject(SujjestSessionadapterService) private sessionAdapterService: SujjestSessionadapterService,
    @Inject(AuthService) private authService: AuthService,
    private afMessaging: AngularFireMessaging
    ){

  }

  ngOnInit() {  
    /*
    this.afMessaging.requestToken
      .subscribe(
        (token) => { window.localStorage.setItem("notificationToken", token);}
      );
      */
  }
}
