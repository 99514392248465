export class Person {
    firstname: string;
    lastname: string;
    username: string;
    email: string;
    userimage: string;
    active: boolean;
    createddate: string;
    visualVoted: boolean;
    visualOnly: boolean;
    visualLeft: boolean;
    visualRight: boolean;
    visualMiddle: boolean;
    visualColor: string;
    purple: boolean;
    blue: boolean;
    green: boolean;
    lightgreen: boolean;
    pink: boolean;
    orange: boolean;
    color: number;
    checked: boolean;
    muted: boolean;
    excluded: boolean;

    public setVisualColor(){
        switch (this.visualColor){
            case "purple": {
                this.blue = false;
                this.green = false;
                this.pink = false;
                this.lightgreen = false;
                this.orange = false;
                this.purple = true;
                break;
            }
            case "green": {
                this.blue = false;
                this.pink = false;
                this.lightgreen = false;
                this.orange = false;
                this.purple = false;
                this.green = true;
                break;
            }
            case "pink": {
                this.blue = false;
                this.green = false;
                this.lightgreen = false;
                this.orange = false;
                this.purple = false;
                this.pink = true;
                break;
            }
            case "lightGreen": {
                this.blue = false;
                this.green = false;
                this.pink = false;
                this.orange = false;
                this.purple = false;
                this.lightgreen = true;
                break;
            }
            case "orange": {
                this.blue = false;
                this.green = false;
                this.pink = false;
                this.lightgreen = false;
                this.purple = false;
                this.orange = true;
                break;

            }
            case "blue": {
                this.green = false;
                this.pink = false;
                this.lightgreen = false;
                this.orange = false;
                this.purple = false;
                this.blue = true;
                break;
            }
        }
    }

    public getFullName(){
        let fullName;
        if(this.firstname){
            fullName = this.firstname;
            if(this.lastname){
                fullName = fullName + ' ' + this.lastname;
            }
        }else{
            fullName = this.email;
        }
        return fullName;

    }

    public setVisualOnly(){
        this.visualOnly = true;
        this.visualLeft = false;
        this.visualRight = false;
        this.visualMiddle = false;
    }

    public setVisualLeft(){
        this.visualLeft = true;
        this.visualRight = false;
        this.visualOnly = false;
        this.visualMiddle = false;
    }

    public setVisualRight(){
        this.visualLeft = false;
        this.visualRight = true;
        this.visualOnly = false;
        this.visualMiddle = false;
    }

    public setVisualMiddle(){
        this.visualLeft = false;
        this.visualRight = false;
        this.visualOnly = false;
        this.visualMiddle = true;
    }
}
