import { Injectable } from '@angular/core';
import * as Rx from 'rxjs';
import {webSocket} from 'rxjs/webSocket';
import { Observable } from 'rxjs';
import { map, filter, catchError, mergeMap, takeUntil, skip, share, retry } from 'rxjs/operators';

@Injectable()
export class SujjestWebsocketService {
  constructor() { }
  private subject: Rx.Subject<MessageEvent>;
private wsURL;

  public connect(url): Rx.Subject<MessageEvent> {
    this.wsURL = url;
    if(this.subject){
      this.subject = null;
    }

    if (!this.subject) {
      //let test = Observable.webSocket(url);
      this.subject = webSocket(url);

      this.subject.subscribe((msg) => msg,
      (err) => this.subject.pipe(map(msg => msg),share()),
      () => this.reconnect(url)
    );
      //console.log("Successfully connected");
    } 
    
    return this.subject;
  }

  private reconnect(url){
    setTimeout(()=>{
      this.connect(url)
    }, 1000)
  }

  public wsServiceReset(){
    //this.subject = Observable.webSocket(this.wsURL);

    this.subject.pipe(retry()).subscribe((msg) => msg,
    (err) => this.subject.pipe(map(msg => msg),share()),
    () => console.log('complete2')
  );

  this.subject.next();
  
  }

  public createErrorTest(){
    this.subject.error('test error to see if kills connection');
  }

  public sendMessage(message){
    console.log(message);
    this.subject.next(JSON.parse(message));
  }

  public closeConnection(){
    if(this.subject){
      //console.log('unsubing');

      this.subject.complete();
      
      this.subject.unsubscribe();
      this.subject = null;
    }


  }


}
