// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  wsURL: 'wss://wsdev.sujjest.com:443',
  firebase: {
    apiKey: 'AIzaSyCMxrTOjmJhay-fmFn9kJ0jt7ZMoMIOuqE',
    authDomain: 'sujjest-81b80.firebaseapp.com',
    projectId: 'sujjest-81b80',
    storageBucket: 'sujjest-81b80.appspot.com',
    messagingSenderId: '153934798024',
    scopes: [
      "email",
      "profile",
      "https://www.google.com/m8/feeds/"
    ],
    discoveryDocs: ['https://people.googleapis.com/$discovery/rest?version=v1']
  }
};
