import { Injectable, Inject }          from '@angular/core';
import * as firebase from 'firebase/app';
import { FirebaseApp } from 'angularfire2';
import { AngularFireMessaging } from '@angular/fire/messaging'
import { AuthService } from './auth.service';
import { BehaviorSubject } from 'rxjs';
import * as Rx from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Router} from '@angular/router';
declare var FirebasePlugin
declare var navigator


@Injectable()
export class MessagingService {
  _messaging: firebase.messaging.Messaging;
  currentMessage = new BehaviorSubject(null);
  uId;
  _authService;
  sessionId;
  public notifyToken: string;
  
  constructor(@Inject(AuthService) authService: AuthService,
   @Inject(FirebaseApp) private _firebaseApp: firebase.app.App,
    private router: Router,
    private route: ActivatedRoute,
    private afMessaging: AngularFireMessaging) { 
    if(typeof navigator.serviceWorker !== "undefined"){
      navigator.serviceWorker.register('ngsw-worker.js').then((registration)=>{
       // console.log('using messaging service!');
        this._messaging = firebase.messaging(this._firebaseApp);
        //this._messaging.useServiceWorker(registration);
        //this.updateToken();
        //console.log(registration);
      });
    }

    /*
    FirebasePlugin.onNotificationOpen(function(data ) {
      let sessionId = data.param1.split("=").pop();
      routeUser(sessionId);
  }, function(error) {
      console.log(error);
  });
  */
    /*
    if(typeof navigator.serviceWorker === "undefined" && typeof FirebasePlugin !== "undefined"){
      //console.log('SETTING NOTIFICATION TOKEN');
      FirebasePlugin.grantPermission();
      FirebasePlugin.getToken(function(token) {
        // save this server-side and use it to push notifications to this device
        window.localStorage.setItem("notificationToken", token);
    }, function(error) {
        console.error(error);
    });
      //console.log(navigator.contacts);
      return;
    }

    */
    
  this._authService = authService;

  }



  updateToken() {

    this._messaging.requestPermission().then(()=>{
      //console.log('Notification permission granted.');
      this.getToken();
    }).catch((err) => {
        // console.log("The error is: " + err); 
      });
  }

  getToken(){
    this._messaging.getToken().then((currentToken)=>{
      if(currentToken){
        //console.log("token: " + currentToken);
        this._authService.deviceKey = currentToken;

      }else {
        //console.log('No Instance ID token available. Request permission to generate one.')
      }
    }).catch((err) => {
      //console.log('An error occurred while retrieving token. ', err);
    });
    this._messaging.onMessage((payload)=>{
      //console.log('payload');
    });
  }

    receiveMessage(msg) {
      //console.log(msg); 
    }
}

