import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { IndexComponent } from './index/index.component';

const routes: Routes = [
  { path: '', loadChildren: () => import('./start-director/start-director.module').then(m => m.StartDirectorModule) },
  { path: 'onboard', loadChildren: () => import('./onboarding/onboarding.module').then(m => m.OnboardingModule)},
  { path: 'login', loadChildren: () => import('./sujjest-login/sujjest-login.module').then(m => m.SujjestLoginModule)},
  { path: 'index', loadChildren: () => import('./index/index.module').then(m => m.IndexModule) },
  { path: 'notifications', loadChildren: () => import('./notification-center/notification-center.module').then(m => m.NotificationCenterModule)},
  { path: 'Public', loadChildren: () => import('./anon-session/anon-session.module').then(m => m.AnonSessionModule)},
  { path: 'profile', loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule)},
  { path: 'session', loadChildren: () => import('./session/session.module').then(m => m.SessionModule)},
  { path: 'AnonSession', loadChildren: () => import('./anon-session/anon-session.module').then(m => m.AnonSessionModule)},
  { path: 'Lunch', loadChildren: () => import('./anon-session/anon-session.module').then(m => m.AnonSessionModule)},
  { path: 'SessionInvite', loadChildren: () => import('./anon-session/anon-session.module').then(m => m.AnonSessionModule)},
  { path: 'colorpick', loadChildren: () => import('./colorwheel/colorwheel.module').then(m => m.ColorwheelModule)},
  { path: '**', loadChildren: () => import('./sujjest-login/sujjest-login.module').then(m => m.SujjestLoginModule)},

]

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule],
  
})
export class AppRoutingModule { }
