export class ProfileSettings{
    firstname="";
    lastname="";
    areacode="000";
    phone="00-000-000-0000";
    email="";
    color="";
    pushComments=1;
    pushOptions=1;
    pushInvites=1;
    pushPrompts=1;
    pushDecisions=1;

}