import { Injectable, Inject } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { SujjestSessionadapterService } from './sujjest-sessionadapter.service';
import {Router, ActivatedRoute, Params} from "@angular/router";
import {Person} from './person';
import * as firebase from 'firebase/app';

declare var gapi: any;
declare var FirebasePlugin;
declare var navigator;
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private sujjestSessionAdapterService: SujjestSessionadapterService;
  public authState: any;
  public displayName: string;
  public userKey: Observable<string>;
  public user: Observable<firebase.User>;
  private userDetails: firebase.User = null;
  public firstName: string;
  public lastName: string;
  public deviceKey: string;
  public googleToken: string;
  public userCredential: BehaviorSubject<any>;
  public authServiceState: BehaviorSubject<any>;
  private tokenid;
  public uid;
  private inviteSession;
  public _firstUseOverride = false;
  formError: boolean = false;
  loginfailure: boolean = false;
  signupfailure: boolean = false;
  resetfailure: boolean = false;
  emailMatchFailure: boolean = false;
  loading: boolean = false;
  showPasswordEmail: boolean = false;
  email: string;
  digesting: boolean = false;
  

  constructor(
      public afAuth: AngularFireAuth,
      private router: Router, 
      private route: ActivatedRoute) {
       
   }

   init(){
    //console.log('Construct Auth Service');
    this.user = this.afAuth.authState;
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    this.userCredential = new BehaviorSubject<any>("");   
    this.authServiceState = new BehaviorSubject<any>("None");
   }

   ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        this.inviteSession = params.session;
        this.tokenid = params.tokenid;
      });

      if(typeof FirebasePlugin !== "undefined"){
        FirebasePlugin.grantPermission();
        navigator.splashscreen.hide();
        FirebasePlugin.getToken(function(token) {
          //console.log(token);
            window.localStorage.setItem("notificationToken", token);
        }, function(error) {
          //console.error('noti error:' + error);
        });
        //FirebasePlugin.setAnalyticsCollectionEnabled(true);
        //FirebasePlugin.setScreenName("index");
        //FirebasePlugin.logEvent("select_content", {content_type: "page_view", item_id: "index"});
        FirebasePlugin.onNotificationOpen((data) => {
          let sessionId = data.param1.split("=").pop();
          let notificationId = data.param2;
          if(data.tap){
            this.loading = true;

              
            this.navNotification(sessionId, notificationId);
            }
        });
      }

  }

  navNotification(sessionID, notificationID){
    this.sujjestSessionAdapterService.requestNotificationReceived(notificationID);
    this.router.navigate(['session'], {queryParams: { session: sessionID}});

  }

  //LOGIN/SIGNUP PHASE
  login(email: string, password: string) {
    console.log("in login");
    //this.loading = true;

    if(email && password){
      this.afAuth.auth.signInWithEmailAndPassword(email, password)
        .then(value => {

          let providerData:any = value.user.providerData;
          console.log(value);
          if(!value.user.emailVerified){

            console.log('email not verified');
            this.formError = true;
            this.loginfailure = true;
            this.loading = false;
            this.emailMatchFailure = false;
            return;
          }


          //console.log(value);
          this.userCredential.next(value);
          this.loading = false;
        })
        .catch(err => {
          this.formError = true;
          this.loginfailure = true;
          this.loading = false;
          console.log('Something went wrong:',err.message);
      });
    }else{
      this.formError = true;
      this.loginfailure = true;
      this.loading = false;
    }
  }
  
  loginWithGoogle() {
    //console.log("in loginWithGoogle");
    this.add_LoadingParameter();
    let googleAuthProvider = this.getGoogleAuthProvider();
    this.authServiceState.next("GoogleWait");
    this.afAuth.auth.signInWithRedirect(googleAuthProvider).catch((e)=>{
      //console.log(e);
      this.loading = false;

    })
  }

  signup(email: string, password: string, firstname: string, lastname: string, 
    hasInviteToken: boolean) {
      this.authServiceState.next("UserVerifyWait");
      return new Promise<String>((resolve,reject)=>{
        // console.log("in signup");
        if(!email || !password || !firstname || !lastname){
          this.formError = true;
          this.signupfailure = true;
          this.loading = false;
          resolve("error");
        }
        this.loading = true;
        let userDetails = {
          firstName: firstname,
          lastName: lastname,
          email: email,
          invite: hasInviteToken
        }

        this.firstName = firstname;
        this.lastName = lastname;
        

        this.afAuth.auth.createUserWithEmailAndPassword(email, password)
          .then(cred=> {
            this.userCredential.next(cred);
            cred.user.updateProfile({
              displayName: firstname + ' ' + lastname,
              photoURL: 'someurl'
            })
            if(!hasInviteToken){
              let actionCodeSettions = {
                // url: 'https://sujjest.page.link/qL6j',
                // url: 'http://localhost:4200/login?firstLogin=true&signup=true&form=login&email='+email,
                url: 'https://www.sujjest.com/app/login?firstLogin=true&signup=true&form=login&email='+email,
                iOS: {
                  bundleId: 'com.sujjest.cordovaclient'
                },
                android: {
                  packageName: 'com.sujjest.conclave'
                },
                
                handleCodeInApp: false
              }
              cred.user.sendEmailVerification(actionCodeSettions);
              window.localStorage.setItem("firstWhenVerified", 'true');
              
            } 
            this.loading = false;
            
            resolve("created");     
          })
          .catch(err => {
            if(err.code == 'auth/email-already-in-use'){
              this.formError = true;
              this.emailMatchFailure = true;
              this.loading = false;
              //console.log('Email already in use!!');
              resolve("sameEmail");
            }else{
              this.formError = true;
              this.signupfailure = true;
              this.loading = false;
              
              //console.log('Something went wrong:', err);
              resolve("error");
            }
          });
      })

  }


  //Phase 2: Handle Credential
  digest_Credential(user: firebase.User, userDetails, isFirstLogin: boolean, 
    navigate: boolean){

      this.firstName = userDetails.firstName;
      this.lastName = userDetails.lastName;
    
      this.email = user.email;

      //maybe the uid isn't getting picked up on native app because we need
      //to reload the current user for some reason.
      if(this.digesting){
        console.log('digestion already running.');
      }else{

        this.digesting = true;
        this.afAuth.auth.currentUser.reload().then(()=>{
          this.uid = this.afAuth.auth.currentUser.uid;
  
          this.userCredential.next(user);
    
          //console.log(this.sujjestSessionAdapterService._wsState.getValue());
    
          if(this.tokenid){
            this.sujjestSessionAdapterService.requestConvertInvite(user.uid, 
              this.tokenid, this.inviteSession)
          }
          //this.sujjestSessionAdapterService._authState.next("Complete");

          this.authServiceState.next("Complete");
          
          this.fill_UserData(user, isFirstLogin, navigate);
          this.digesting = false;
        });
      }



    
  }
  
  //Phase 3: Fill data adapter
  fill_UserData(user: firebase.User, isFirstLogin: boolean, navigate: boolean){
    //this.sujjestSessionAdapterService.user = user;
    this.deviceKey = window.localStorage.getItem("notificationToken")
    if(navigate){
      this.navigate_User(this.userCredential.getValue(), isFirstLogin);
    }
    
  }

  //Phase 4: Navigate
  navigate_User(userCred: firebase.auth.UserCredential, isFirstLogin: boolean){
    if(isFirstLogin){
      
      this.router.navigate(['/colorpick'], { queryParamsHandling: 'preserve' });
    }else{
      this.router.navigate([''], { queryParamsHandling: 'preserve' });
    }
  }


  /*
  * Update user profile in firebase
  * @param {string} firstName
  * @param {string} lastName
  */
  update_FirebaseProfile(firstName, lastName){
    this.afAuth.auth.currentUser.updateProfile({
      displayName: firstName + ' ' + lastName,
      photoURL: 'someurl'
    })
  }

  /*
  * Retrieve google auth provider for google login permissions
  * @return {firebase.auth.GoogleAuthProvider} googleAuthProvider
  */
  getGoogleAuthProvider(){
    let googleAuthProvider = new firebase.auth.GoogleAuthProvider();
    googleAuthProvider.addScope('https://www.googleapis.com/auth/contacts.readonly');
    googleAuthProvider.addScope('profile');
    googleAuthProvider.addScope('email');
    return googleAuthProvider;
  }

  /*
  * Add loading parameter to querystring
  */
  add_LoadingParameter(){
    let urlTree = this.router.parseUrl(this.router.url);
    urlTree.queryParams['loading'] = 'true';
    this.router.navigateByUrl(urlTree);
    this.loading = true;
  }

  send_EmailVerification(email){
    let actionCodeSettions = {
      url: 'http://localhost:4200/login?signup=true&form=login&email='+email,
      handleCodeInApp: true
    }
    this.afAuth.auth.currentUser.sendEmailVerification(actionCodeSettions);
    window.localStorage.setItem("firstWhenVerified", 'true');
  }

  logout() 
  {
    this.loading = false;

    this.afAuth
      .auth
      .signOut().then(value => {
        //console.log('Signed Out!');
        /*
        this.authState = "";
        this.displayName = "";
        this.userKey=null;
        this.user=null;
        this.userDetails= null;
        this.firstName=null;
        this.lastName=null;
        this.googleToken=null;
        this.userCredential=null;
        this.tokenid = null;
        this.inviteSession = null;
        */
        this.googleToken = '';
        window.localStorage.removeItem("googleToken");
        gapi.auth.signOut();
      });

  }

  logoutActivate() 
  {


    this.afAuth
      .auth
      .signOut().then(value => {
        //console.log('Signed Out!');
        gapi.auth.signOut();
      });
      this.router.navigate(['/'], {queryParams: {form: "verify"}});
  }

  resetPassword(email: string) {
    var auth = firebase.auth();
    this.loading = true;
    if(!email){
      this.loading = false;
      this.formError = true;
      this.resetfailure = true;
      return;
    }
    auth.sendPasswordResetEmail(email)
      .then(() => {
        this.loading = false;
        this.showPasswordEmail = true;
        //console.log("email sent");
      })
      .catch((error) => {
        this.loading = false;
        this.formError = true;
        this.resetfailure = true;
        console.log(error);})
  }

  /*
  * Check if user is trying to login for first time
  * @param {firebase.auth.UserCredential} userCred
  * @return {boolean} userFirstLogin
  */
  check_IsFirstLogin(userCred: firebase.auth.UserCredential){
   // console.log(userCred);
    if(userCred && !this._firstUseOverride){
      return userCred.additionalUserInfo.isNewUser;
    }
    this.afAuth
    return false;
  }

  /*
  * Check if user is firebase native
  * @param {Firebase.User} user
  * @return {boolean} isFirebase
  */
  check_IsFirebaseUser(user: firebase.User){
    if(user.providerId == 'firebase'){
      return true;
    }
    return false;
  }

  /*
  * Set google token for contacts
  * @param {firebase.auth.UserCredential} userCred
  */
 set_GoogleUserToken(userCred: any){

  let token = userCred.accessToken;
  window.localStorage.setItem("googleToken", token);
 }

 /*
 * Set auth details to sujjest server
 * @param {string} firstName
 * @param {string} lastName
 * @param {string} email
 * @param {string} userName
 */
 set_SujjestAuthDetails(firstName, lastName, email, userName){
  
  this.sujjestSessionAdapterService.requestProfileCheck(firstName, lastName, email, userName);
 }

 get authenticated(): boolean {
  if (this.userDetails == null ){
    return false;
  } else{
    return true;
  }
}

get currentUser(): any {
  return this.authenticated ? this.authState : null;
}

get currentUserObservable(): any {
  return this.afAuth.authState
}

get currentUserId(): string {
  return this.authenticated ? this.authState.uid : '';
}

get currentUserDisplayNHame(): string{
  if(!this.authState) { return this.authState['displayName'] || 'User without a name'}
}

saveEmailLocal(email){
  window.localStorage.setItem("sujjestEmail", email);
}
}
