import { FinishVote } from "./finish-vote";

export class DecisionFinished {
    decisionfinishId: number;
    sessionId: number;
    decisionId: number;
    optionId: number;
    profileId: string;


}
