import { Decision } from './decision';
import { Person } from './person';
import { Option } from './option';
import { SujjestMessage } from './sujjest-message';
import { DecisionFinished } from './decision-finished';
import { Profilevote } from './profilevote';
import { FinishVote } from './finish-vote';

interface Serializable<T> {
    deserialize(input: Object): T;
}

export class Session implements Serializable<Session>{
    session_id: number;
    sessionName: string;
    createdBy: string;
    createdOn: any;
    sessionStatus: any;
    updatedOn: any;
    sessionDecisions: Decision[] = new Array();
    sessionDecisionFinished: DecisionFinished[] = new Array();
    sessionPersons: Person[] = new Array();
    sessionMessages: SujjestMessage[] = new Array();
    expanded: boolean = false;
    timer: any;

    constructor() {

    }

    public findOptionDecisionId(optionID){
        for(let dec=0; dec<=this.sessionDecisions.length-1;dec++){
            let decision: Decision = this.sessionDecisions[dec];
            for(let opt=0; opt<=decision.options.length-1;opt++){
                let option: Option = decision.options[opt];
                if(option.optionId == optionID){
                    return option.decisionId;
                }
            }
        }
    }

    findOption(optionID){
        for(let dec=0; dec<=this.sessionDecisions.length-1;dec++){
            for(let opt=0; opt<=this.sessionDecisions[dec].options.length-1;opt++){
                if(this.sessionDecisions[dec].options[opt].optionId == optionID){
                    return this.sessionDecisions[dec].options[opt];
                }
            }
        }
    }

    setUserActive(userId){
        for(let i=0; i<=this.sessionPersons.length-1; i++){
            let person: Person = this.sessionPersons[i];
            if(person.username == userId){
                person.active = true;
            }
        }
    }

    public setUpdatedNow(){
        this.updatedOn = new Date();
    }

    public getActiveUsers(){
        let usrIds: String[] = new Array();
        for(let usr=0; usr<=this.sessionPersons.length-1; usr++){
            let person: Person = this.sessionPersons[usr];
            if(person.active){
                usrIds.push(person.username);
            }
        }
        return usrIds;
    }

    public getPerson(profileId){
        for(let per=0;per<=this.sessionPersons.length-1; per++){
            let person: Person = this.sessionPersons[per];
            if(person.username==profileId){
                return person;
            }
        }
    }

    public getPersonFirstName(profileId){
        let person = this.getPerson(profileId);
        if(person){
            /*
            if(person.username.length>15){
                return person.firstname;
            }else{
                return person.email.substr(0, person.email.indexOf('@'));
            }
            */
           return person.firstname;
        }
        return '';
    }

    public getPersonMuteStatus(profileId){
      let person =  this.getPerson(profileId);
      if(person){
        return person.muted;
      }  
      return false;
    }

    public getDecision(decisionId){
        for(let dec=0; dec<=this.sessionDecisions.length-1;dec++){
            let decision: Decision = this.sessionDecisions[dec];
            if(decision.decisionId==decisionId){
                return decision;
            }
        }
    }

    public getFinishedDecision(decisionId){
        for(let dec=0; dec<=this.sessionDecisionFinished.length-1;dec++){
            let decisionfinished: DecisionFinished = this.sessionDecisionFinished[dec];
            if(decisionfinished.decisionId==decisionId){
                return decisionfinished;
            }
        }
    }

    public get_OptionCount(){
        let optCount: number = 0;
        for(let dec of this.sessionDecisions){
          let decObj = <Decision>dec;
    
          if(decObj.options && decObj.options.length && !this.checkDecisionFinished(decObj.decisionId)){
            for(let opt of decObj.options){
              if(opt.profileVotes.length>0){
                optCount++;
              }
            }
          }
        }
        return optCount;
    }

    public getAnimationColorName(colorId){
        switch(colorId) {
          case 1: {
            return "pink";
          }
          case 2: {
            return "orange";
          }
          case 3: {
            return "lightGreen";
          }
          case 4: {
            return "green";
          }
          case 5: {
            return "blue";
          }
          case 6: {
            return "purple";
          }
        }
       }

       public getOptionVoteCount(optionId){

        for(let i=0; i<=this.sessionDecisions.length-1; i++){

            let decision: Decision = this.sessionDecisions[i];

            if(decision.finishingActiveUserIds.length>0){
                if(decision.options.length>0){
                    for(let opt=0; opt<=decision.options.length-1; opt++){
                        let option: Option = decision.options[opt];
                        if(option.optionId == optionId){
                            let voteCount = 0;
                            for(let pv=0; pv<=option.profileVotes.length-1;pv++){
                                let profileVote: Profilevote = option.profileVotes[pv];
                                if(this.checkUserActiveFinished(profileVote.profileId, decision.decisionId)){
                                    voteCount++;
                                }
                            }
                        
                         return voteCount;
                        }
                    }
                }
            }

            if(decision.options.length>0){
                for(let opt=0; opt<=decision.options.length-1; opt++){
                    let option: Option = decision.options[opt];
                    if(option.optionId == optionId){
                        let voteCount = 0;
                        for(let pv=0; pv<=option.profileVotes.length-1;pv++){
                            let profileVote: Profilevote = option.profileVotes[pv];
                            if(this.checkUserActive(profileVote.profileId)){
                                voteCount++;
                            }
                        }
                    
                     return voteCount;
                    }
                }
            }
        }
        return 0;
    }

    public checkUserActiveFinished(profileId, decisionId){
        let decision: Decision = this.getDecision(decisionId);
        return decision.checkUserActiveFinishing(profileId);
    }

    public checkDecisionFinished(decID: number){
        for(let i=0; i<=this.sessionDecisionFinished.length-1;i++){
            let decFin:DecisionFinished = this.sessionDecisionFinished[i];
            if(decFin.decisionId == decID){
                return true;
            }
        }
        return false;
    }

    checkUserActive(userId){
        for(let i=0; i<=this.sessionPersons.length-1; i++){
            let person: Person = this.sessionPersons[i];
            if(person.username == userId){
                return person.active;
            }
        }
    }

    checkHasActiveDecisions(){
        if(this.sessionDecisionFinished.length == this.sessionDecisions.length){
            return false;
        } else {
            return true;
        }
    }

    /*
    * Verifies if user has voted for option in session
    * @param {string} userId
    * @param {number} optionId
    * @param {number} decisionId
    * @return {boolean} hasVoted
    */ 
    checkUserVotedOption(userId, decisionId, optionId){
        let decision: Decision = this.getDecision(decisionId);
        for(let opt=0;opt<=decision.options.length-1;opt++){
            let option: Option = decision.options[opt];
            if(option.optionId == optionId){
                for(let pv=0;pv<=option.profileVotes.length-1;pv++){
                    let profileVote: Profilevote = option.profileVotes[pv];
                    if(profileVote.profileId == userId){
                        return true;
                    }
                }
            }
        }
        return false;
    }

    /*
    * Verifies if user has voted for finish in session
    * @param {string} userId
    * @return {boolean} votedForFinish
    */
    public checkFinishVote(profileId){
        for(let fv=0; fv<=this.sessionDecisions[0].finishVotes.length-1;fv++){
            let finishVote: FinishVote = this.sessionDecisions[0].finishVotes[fv];
            if(finishVote.profileId == profileId){
                return true;
            }
        }
        return false;
    }

    /*
    * Get finished vote count on session.
    */
   public get_FinishVoteCount(){
    let finishVoteCount = 0;
    //Find FinishVotes
    if(this.sessionDecisions[0].finishVotes.length>0){
      let decision:Decision = this.sessionDecisions[0];
      for(let fv=0; fv<=decision.finishVotes.length-1;fv++){
        let finishVote: FinishVote = decision.finishVotes[fv];
        for(let per=0; per<=this.sessionPersons.length-1;per++){
          let person: Person = this.sessionPersons[per];
          if(person.username == finishVote.profileId ){
            finishVoteCount++;
          }
        }
      }
    }
    return finishVoteCount;
   }

   public get_FinishMargin(decisionID){
    let usedVoteCount = this.get_DecisionFinishVoteCount(decisionID);
    return 100*(1 - (usedVoteCount + 1)/this.get_ActiveUserCount())
   }

   public get_IncludedUsers(){
       let includedUsers: Array<Person> = new Array();
       for(let usr=0; usr<= this.sessionPersons.length-1; usr++){
           let person:Person = this.sessionPersons[usr];
           if(person.excluded == false || person.excluded == null ){
               includedUsers.push(person);
           }
       }
       //console.log(includedUsers);
       return includedUsers;
   }

   public get_TrackSlotCount(){
       let slotCount = 0;
       
       for(let sl=0; sl<=this.sessionPersons.length-1; sl++){

           let person: Person = this.sessionPersons[sl];

           if(person.excluded == false || person.excluded == null){
            //console.log(person);
                slotCount++;
           }
        }
       
       return slotCount;
   }

   public get_ActiveUserCount(){
       let activeUserCount=0;
       for(let usr=0; usr<=this.sessionPersons.length-1; usr++){
           let person: Person = this.sessionPersons[usr];
           if(person.active){
               activeUserCount++
           }
       }
       return activeUserCount;
   }

   

    /*
    * Get finished vote count on session.
    */
   public get_DecisionFinishVoteCount(decisionId){
    let finishVoteCount = 0;
    //Find FinishVotes
    let decision = this.getDecision(decisionId);
    if(decision.finishVotes && decision.finishingFinishVotes.length>0){
        for(let fv=0; fv<=decision.finishingFinishVotes.length-1;fv++){
            let finishVote: FinishVote = decision.finishingFinishVotes[fv];
            for(let per=0; per<=this.sessionPersons.length-1;per++){
              let person: Person = this.sessionPersons[per];
              if(person.username == finishVote.profileId ){
                finishVoteCount++;
              }
            }
          }
    }else if(this.sessionDecisions[0].finishVotes.length>0){
      let decision:Decision = this.sessionDecisions[0];
      for(let fv=0; fv<=decision.finishVotes.length-1;fv++){
        let finishVote: FinishVote = decision.finishVotes[fv];
        for(let per=0; per<=this.sessionPersons.length-1;per++){
          let person: Person = this.sessionPersons[per];
          if(person.username == finishVote.profileId ){
            finishVoteCount++;
          }
        }
      }
    }
    return finishVoteCount;
   }

    deserialize(input) {
        this.session_id = input.session_id;
        this.createdBy = input.createdBy;
        this.sessionName = input.sessionName;

        return this;
    }

 


    public clearUsersActiveStatus(){
        for(let i=0; i<=this.sessionPersons.length-1; i++){
            let person: Person = this.sessionPersons[i];
            person.active = false;
        }
    }

    public removePerson(userId){
        let person: Person = this.getPerson(userId);
        this.sessionPersons = this.sessionPersons.filter(obj => obj !== person);
        
    }

    public emptyProfileHighlight(profileId){
        let person: Person = this.getPerson(profileId);
        person.visualLeft = false;
        person.visualMiddle = false;
        person.visualOnly = false;
        person.visualRight = false;

    }
    
    public personHighlight(profileId, colorId){
        //console.log(colorId);
        let pers: Person = this.getPerson(profileId);
        if(pers.visualVoted){
            let animColor = this.getAnimationColorName(Math.floor(colorId));
            //console.log(animColor);
            pers.visualColor = animColor;
            pers.setVisualColor()
        }
    }

    public setSessionUpdatedNow(){
        let updatedDate = Date();
        this.updatedOn = updatedDate.toString();
    }

    public check_WinnableOptions(){
        for(let i=0; i<=this.sessionDecisions.length-1; i++){
            let decision: Decision = this.sessionDecisions[i];
            if(!this.checkDecisionFinished(decision.decisionId)){
                let optionWinnerCount = 0;
                let finishVoteCount = this.get_FinishVoteCount();
                let personCount = this.get_TrackSlotCount();
                for(let opt=0; opt<=decision.options.length-1;opt++){
                    let option: Option = decision.options[opt];
                    let optionVoteCount = 0;
                    for(let pv=0; pv<=option.profileVotes.length-1;pv++){
                        let profileVote: Profilevote = option.profileVotes[pv];
                        if(this.checkUserActive(profileVote.profileId)){
                            optionVoteCount++;
                        }
                    }
                    if(optionVoteCount >= (personCount-(finishVoteCount+1)) 
                    && optionVoteCount <= (personCount-finishVoteCount)){
                        optionWinnerCount++;
                        if(optionWinnerCount>1){
                            return decision.decisionId;
                        }
                    }
                }
            }

            
        }
        return 1;
    }

    public checkOptionPotentialWinner(optionId, decisionId){
        for(let i=0; i<=this.sessionDecisions.length-1; i++){
            let decision: Decision = this.sessionDecisions[i];
            let finishVoteCount = this.get_FinishVoteCount();
            let personCount = this.get_TrackSlotCount();
            if(decision.decisionId == decisionId){
                for(let opt=0; opt<=decision.options.length-1;opt++){
                    let option: Option = decision.options[opt];
                    if(option.optionId == optionId){
                        let optionVoteCount = 0;
                        for(let pv=0; pv<=option.profileVotes.length-1;pv++){
                            let profileVote: Profilevote = option.profileVotes[pv];
                            if(this.checkUserActive(profileVote.profileId)){
                                optionVoteCount++;
                            }
                        }
                        if(optionVoteCount >= (personCount-(finishVoteCount+1)) 
                        && optionVoteCount <= (personCount-finishVoteCount)){
                            return true;
                        }
                    }

                }
            }
        }
    }

    public checkFinishWinner(){
        for(let i=0; i<=this.sessionDecisions.length-1; i++){
            let decision: Decision = this.sessionDecisions[i];
            let finishVoteCount = this.get_FinishVoteCount();
            let personCount = this.get_TrackSlotCount();
            if(!this.checkDecisionFinished(decision.decisionId)){
                for(let opt=0; opt<=decision.options.length-1;opt++){
                    let option: Option = decision.options[opt];
                        let optionVoteCount = 0;
                        for(let pv=0; pv<=option.profileVotes.length-1;pv++){
                            let profileVote: Profilevote = option.profileVotes[pv];
                            if(this.checkUserActive(profileVote.profileId)){
                                optionVoteCount++;
                            }
                        }
                        if(optionVoteCount >= (personCount-(finishVoteCount+1)) 
                        && optionVoteCount <= (personCount-finishVoteCount)){
                            return true;
                        }
                }
            }

        }
    }

    public getFinishWinners(){
        let finishWinners: number[] = new Array();
        for(let i=0; i<=this.sessionDecisions.length-1; i++){
            let decision: Decision = this.sessionDecisions[i];
            let finishVoteCount = this.get_FinishVoteCount();
            let personCount = this.get_TrackSlotCount();
            if(!this.checkDecisionFinished(decision.decisionId)){
                for(let opt=0; opt<=decision.options.length-1;opt++){
                    let option: Option = decision.options[opt];
                        let optionVoteCount = 0;
                        for(let pv=0; pv<=option.profileVotes.length-1;pv++){
                            let profileVote: Profilevote = option.profileVotes[pv];
                            if(this.checkUserActive(profileVote.profileId)){
                                optionVoteCount++;
                            }
                        }
                        if(optionVoteCount >= (personCount-(finishVoteCount+1)) 
                        && optionVoteCount <= (personCount-finishVoteCount)){
                            finishWinners.push(decision.decisionId);
                        }
                }
            }

        }
        return finishWinners;
    }



}
