

import { Injectable, Inject, NgZone, ChangeDetectorRef} from '@angular/core';
import { Observable, Subject, BehaviorSubject ,  SubscriptionLike as ISubscription, interval, of } from 'rxjs';
import { map, filter, catchError, mergeMap, takeUntil, skip } from 'rxjs/operators';
import {Router, ActivatedRoute, Params} from "@angular/router";
import { HttpClient} from '@angular/common/http';
import { AuthService } from './auth.service';
import { SujjestWebsocketService } from './sujjest-websocket.service';
import { AuthResponse } from './auth-response';
import * as firebase from 'firebase/app';
import { WsRefreshRequest } from './ws-refresh-request'
import { Session } from './session';
import { Decision } from './decision';
import { Option } from './option';
import { DecisionFinished } from './decision-finished';
import { Profilevote } from './profilevote';
import { FinishVote } from './finish-vote';
import { Person } from './person';
import { ProfileSettings } from './profilesettings';
import { SujjestMessage } from './sujjest-message';
import { NotificationItem } from './notification'

import { environment } from '../environments/environment';
declare var cordova;
declare var navigator;
declare var Connection;



declare var gapi: any;
var FirebasePlugin;

const SUJJEST_URL = environment.wsURL;

export interface Message {
  author: string,
  message: string
}

@Injectable({
  providedIn: 'root',
})
export class SujjestSessionadapterService {
  public messages: Subject<MessageEvent>;
  public uId: string;
  public _wsService: SujjestWebsocketService;
  public user:firebase.User;
  public _sessions: Session[] = new Array();
  public _notifications: NotificationItem[] = new Array();
  public _authState:BehaviorSubject<any>;
  public emptySessions: boolean;
  public _wsState:BehaviorSubject<any>;
  public googContacts: Person[] = new Array();
  public anonSessionDumped: boolean;
  public sessionAddSubject: BehaviorSubject<any>;
  public _refreshRequest: boolean;
  public _persons: Person[] = new Array();
  private timer: any;
  private wsSub: ISubscription;
  public firstName: string;
  public lastName: string;
  private email: string;
  private data;
  public invited;
  private reconnectTimeout;
  private paused: boolean = false;
  private offline: boolean = false;
  private gapiFilled: boolean = false;
  private keepAliveSubscription;
  public loading: boolean = false;
  public backoffInterval = 500;
  public pauseTime: Date = new Date('August 19, 1975 23:15:30');
  public profileSettings: ProfileSettings = new ProfileSettings();
  private authSubscription;
  public tokenRejected = false;
  public wsError = false;
  public comingOnline = false;
  private initialHref;
  public restartError = false;
  public sessionLocation = 0;
  private errorConnectCheckInterval;
  private reconnecting = false;
  private awaitReconnect = false;
  public anonAccessWait = false;


  constructor(
    @Inject(AuthService) public sujjestAuthService: AuthService,
    wsService: SujjestWebsocketService, 
    private http:HttpClient,
    private router: Router,
    private zone:NgZone,
    
    ) {
    //console.log('construct sessionAdapter');
    this.initialHref = window.location.href;
    //console.log(this.initialHref);
    this.sujjestAuthService.init();
    this._wsService = wsService;
    this._authState = new BehaviorSubject<any>("None");
    this._wsState = new BehaviorSubject<any>("None");
    this.sessionAddSubject = new BehaviorSubject<any>("1");
    this.sujjestAuthService.userCredential.subscribe((val: firebase.User)=>{
      //console.log(val.uid);

      if(
        !this.messages 
        ){
        
        this.messages = this.getWsService();
      }

    })
    this.anonSessionDumped = false;
    this._refreshRequest = false;

    if(typeof cordova !== "undefined"){
      //console.log('cordova present, adding network offline listener');
      document.addEventListener("offline", ()=>this.onOffline(), false);
      //console.log('cordova present, adding network online listener');
      document.addEventListener("online", ()=>this.onOnline(), false);
      //console.log('cordova present, adding resume event');
      document.addEventListener("resume", ()=>this.onResumeCode(), false);
      //console.log('cordova present, adding resume event');
      document.addEventListener("pause", ()=>this.onPauseCode(), false);

      //this.getPhoneContacts();
      
    }

    this.setKeepAlive();


                      
   }

   setKeepAlive(){
    this.keepAliveSubscription = this.pollKeepAlive().subscribe((val)=>{
      if(val==1){
        let keepAliveMessage={
          MsgType: "KeepAlive",
          userID: this.getCurrentUserID()
        }
        //console.log(keepAliveMessage);
        this._wsService.sendMessage(JSON.stringify(keepAliveMessage));
      }

    });
   }

   pollKeepAlive(){

     return interval(15000).pipe(map(()=>{
       if(!this.offline){
        return 1;
       }
      
     }))
   }

   disconnectWebsocket(){
    this.messages.unsubscribe();
   }

   reconnectWebsocket(){
     this.reconnecting = true;
    setTimeout(()=>{
      this.messages = this.getWsService();
      this.comingOnline = false;
      this.offline = false;
      this.wsError = false;
    
      setTimeout(()=>{
        
        let token = window.localStorage.getItem("notificationToken");
        this.sendAuth(this.getCurrentUserID(), token);
        //this.requestRefresh();
        this.reconnecting = false;
      },300)
      
    },1500);
   }

   restartApp(){

     this.restartError = false;
     if(typeof navigator.app !== 'undefined'){
      navigator.app.loadUrl(this.initialHref.replace('?loading=true',''), {loadUrlTimeoutValue: 60000});
      navigator.splashscreen.show(); 
    }else{
      window.location = this.initialHref.replace('?loading=true','');
    }

    console.log(this.initialHref);
    //

   }

   onOffline(){
    //('in onOffline');
    this._wsState.next("Offline");
    this.loading = true;
    this.offline = true;

    if(this.sujjestAuthService.authServiceState.getValue()!=="GoogleWait" && 
    this._wsState.getValue()!=="None"){
      //this.messages.unsubscribe();
      //this._wsService.closeConnection();
      //this._wsService.createErrorTest();
      this.pauseTime = new Date();
    }
    let networkState = navigator.connection.type;
    if(this.paused){
      let sessionLocation = window.localStorage.getItem("sessionLocation");
      window.localStorage.setItem("restartSession", sessionLocation);
      this.restartError = true;
    }
 
   }

   onPauseCode(){
   //console.log('in onPauseCode');
    let authState = this.sujjestAuthService.authServiceState.getValue();
    if(authState=="GoogleWait"||
    authState=="None" ||
    authState=="UserVerifyWait"){
      //console.log('skipping pause');
      return;
    }
    this.loading = true;
    this.paused = true;
    this.wsError = false;

    if(this._wsState.getValue()=="Filled"){
      this.pauseTime = new Date();
    }

   }

   onResumeCode(){
   // console.log('in onResumeCode');
  //  console.log('paused' + this.paused);
  //  console.log('authstate' + this.sujjestAuthService.authServiceState.getValue());
  //  console.log('this.comingOnline' + this.comingOnline);
  //  console.log('this.offline' + this.offline);
  //  console.log('restartError' + this.restartError);
  //  console.log('wsError' + this.wsError);

    if(this.paused){
      this.paused = false;

      //if went offline while paused to a connection state of none then restart.
      if(this.restartError){
        this.restartApp();
        return;
      }

      let networkState = navigator.connection.type;
      if(networkState == Connection.NONE){
        //Don't do anything because phone is disconnected
        this.handleWebSocketError('manualErrorForce');
        return;
      }else{
        this.disconnectWebsocket();
        this.awaitReconnect = true;
        setTimeout(()=>{
          if(this.awaitReconnect == true){
            this.reconnectWebsocket();
          }  
        },7000);

/*
        this.keepAliveSubscription.unsubscribe();
        this.disconnectWebsocket();
        this.awaitReconnect = true;
        console.log('disconnected ws');
        setTimeout(()=>{
          if(this.awaitReconnect){
              console.log('ws failed to recon on its own. Manual.');
              this.awaitReconnect = false;
              this.reconnectWebsocket();
              this.setKeepAlive();
          }
        },6000)
*/
      }
    }



   }

   onOnline(){
   // console.log('in onOnline');

    //If paused mark as errored. (may need to change this around)
    if(this.paused){
      return;
    }

    //If already coming online skip
    if(this.comingOnline){
      return;
    }

    if(this.errorConnectCheckInterval == null){
      console.log('disconnect ws');
      //this.disconnectWebsocket();
    }


   }

   handleWebSocketError(err){
   // console.log('in handleWebSocketError');
      if(this.paused){
        //if paused, ignore and reconnect on resume
        let sessionLocation = window.localStorage.getItem("sessionLocation");
        window.localStorage.setItem("restartSession", sessionLocation);
        this.restartError = true;
        return;
      }


      if(this.errorConnectCheckInterval ==null){
        this.loading=true;
        this.errorConnectCheckInterval = setInterval(()=>{
        //  console.log('errorConnectCheckInterval Run');

          if(typeof Connection === 'undefined'){
         //   console.log('errroConnect Reconnecting...');
            this.reconnectWebsocket();
            clearInterval(this.errorConnectCheckInterval);
            this.errorConnectCheckInterval = null;
          }else{
            let networkState = navigator.connection.type;
        //    console.log('network state' + networkState);
            if(networkState == Connection.NONE){
              
              //Don't do anything because phone is disconnected wait for next interval
         //    console.log('no network need new interval loop.')
              return;
            }else{
         //     console.log('errroConnect Reconnecting...');
              this.reconnectWebsocket();
              clearInterval(this.errorConnectCheckInterval);
              this.errorConnectCheckInterval = null;
            }
          }


        }, 50);
      }

   }

   getWsService(){
   // console.log('in getWsService');
     const myObserver = {
      next: response => this.parseWsMessage(response),
      error: err => this.handleWebSocketError(err),
      complete: () => {
        if(!this.paused){
          if(this.getCurrentUserID() == null){
            return;
          }
          this.awaitReconnect = false;
          setTimeout(()=>{
            this.messages = this.getWsService();
            this.comingOnline = false;
            this.offline = false;
            this.wsError = false;
          
            setTimeout(()=>{
              
              if(!this.anonAccessWait){
                let token = window.localStorage.getItem("notificationToken");
                this.sendAuth(this.getCurrentUserID(), token);
                //this.requestRefresh();
              }

            },300)
            
          },1500);
        }

        /*
        let token = window.localStorage.getItem("notificationToken");
        this.messages = this.getWsService();
        if(this.sujjestAuthService.authServiceState.getValue()=="Complete"){
          this.sendAuth(this.getCurrentUserID(), token);
        }
*/
      },
    };

    this._wsService = null;
    this._wsService = new SujjestWebsocketService();
     let messageEventSubject:Subject<MessageEvent> = this._wsService.connect(SUJJEST_URL);
     messageEventSubject.subscribe(myObserver);


     return messageEventSubject;
   }

   parseWsMessage(response: MessageEvent){
    let authorData = "NoAuthor";
    console.log(JSON.stringify(response));
    let genericMessage: any = response;
    
    switch(genericMessage.MsgType) {
      case "Req:Auth": {

          this._wsState.next("AuthWait")
          if(this.sujjestAuthService.authServiceState.getValue() === "Complete" && !this.anonAccessWait){
            let token = window.localStorage.getItem("notificationToken");
            if(this.getCurrentUserID() == null){
              setTimeout(()=>{
                this.sendAuth(this.getCurrentUserID(), token);
              },1500)
            }
            this.sendAuth(this.getCurrentUserID(), token);
          }else{
            let authListen = this.sujjestAuthService.authServiceState.subscribe((val)=>{
              if(val == "Complete"){
                let token = window.localStorage.getItem("notificationToken");
                this.sendAuth(this.getCurrentUserID(), token);
                authListen.unsubscribe();
              }
            })      
          }

        break;
      }
      case "Act:Auth": {
        this._wsState.next("Complete");
        //console.log(genericMessage);
        //console.log(this.getCurrentUserID());
        if(genericMessage.anonUser !== null){
          this.uId = genericMessage.anonUser;
          this.sujjestAuthService.uid = genericMessage.userID;
        }else{
          this.uId = genericMessage.userID;
          this.sujjestAuthService.uid = genericMessage.userID;
        }

        //console.log('uId is:' + genericMessage.userID);
        if(genericMessage.userID.length>15 && genericMessage.userID== this.getCurrentUserID()){
          this.requestProfileCheck(
            this.sujjestAuthService.firstName, this.sujjestAuthService.lastName, this.sujjestAuthService.email, 
            this.getCurrentUserID());
        }else{
          //Anon path
          if(genericMessage.userID !=="NOTFOUND"){
            window.localStorage.setItem("AnonUserID", genericMessage.userID);
          }

          //this.requestRefresh();
        }

        this.loading = true;
        break;
      }
      case "Act:CreateAnonUser": {
        //this._wsState.next("Complete");
        this.uId = genericMessage.userID;
        this.sujjestAuthService.uid = genericMessage.userID;
        window.localStorage.setItem("AnonUserID", genericMessage.userID);
        this.anonAccessWait = false;

       
        

      }
      case "Server:UserVerify": {
        this.invited = genericMessage.invited;
        if(this._wsState.getValue() == "Complete"){
          this.uId = genericMessage.userID;
          //console.log('uId is:' + genericMessage.userID);
          if(this.uId.length>15){
            this.requestRefresh();
          }

        }
        break;
      }
      case "Act:SesAdd": {
        let sessionAddAct: intAddSession = genericMessage;
        this.addSession(sessionAddAct);
        break;
      }
      case "Act:DecAdd": {
        let decisionAddAct: intAddDecision = genericMessage;
        let ses:Session = this.findSession(decisionAddAct.sessionID);
        if(ses){
          this.addDecision(decisionAddAct);
        }
        break;
      }
      case "Act:DecFinish": {
        let decisionFinishAct: intDecFinish = genericMessage;
        let ses:Session = this.findSession(decisionFinishAct.sessionID);
        if(ses){
          this.finishDecision(decisionFinishAct);
        }
        
      break;
      }
      case "Act:RejectToken":{
        this.tokenRejected = true;
        this.loading = false;
        this.uId = null;
        this._wsState.next("None");
        break;
      }
      case "Act:LeaveSession": {
        let leaveSessionAct: intRemoveUser = genericMessage;
        let ses:Session = this.findSession(leaveSessionAct.sessionID);
        if(ses){
        this.removeUserFromSession(leaveSessionAct);
        }
        break;
      }
      case "Act:OptAdd": {
        let optionAddAct: intAddOption = genericMessage;
        let ses:Session = this.findSession(optionAddAct.sessionID);
        console.log(genericMessage);
        if(ses){
        this.addOption(optionAddAct);
        }

        break;
      }
      case "Act:upVoteOption": {
        let optionUpVoteAct: intVoteOption = genericMessage;
        let ses:Session = this.findSession(optionUpVoteAct.sessionID);
        if(ses){
        this.upVoteOption(optionUpVoteAct);
        }
        break;
      }
      case "Act:downVoteOption": {
        let optionDownVoteAct: intVoteOption = genericMessage;
        let ses:Session = this.findSession(optionDownVoteAct.sessionID);
        if(ses){
        this.downVoteOption(optionDownVoteAct);
        }
        break;
      }
      case "Act:upFinish": {
        let upFinishAct: intVoteFinish = genericMessage;
        let ses:Session = this.findSession(upFinishAct.sessionID);
        if(ses){
        this.upFinish(upFinishAct);
        }
        break;
      }
      case "Act:downFinish": {
        let downFinishAct: intVoteFinish = genericMessage;
        let ses:Session = this.findSession(downFinishAct.sessionID);
        if(ses){
        this.downFinish(downFinishAct);
        }
        break;
      }
      case "Act:UserAdd": {
        let addUserAct: intAddPerson = genericMessage;
        let ses:Session = this.findSession(addUserAct.sessionID);
        if(ses || addUserAct.userID==this.getCurrentUserID()){
          this.addPerson(addUserAct);
        }
        break;
      }
      case "Act:sessionMessage":{
        let sesMessage: intAddMessage = genericMessage;
        let ses:Session = this.findSession(sesMessage.sessionID);
        if(ses){
        this.addMessage(sesMessage);
        }
        break;
      }
      case "Act:ProfileUpdate":{
        let profUpdate = genericMessage;
        this.updateProfile(profUpdate.userID, profUpdate.firstName, profUpdate.lastName);
        break;
      }
      case "Act:ProfileSettings":{
        let profSettings = genericMessage;
        this.setProfileSettings(profSettings);
        break;
      }
      case "Act:MuteNotifications":{
        let muteUpdate = genericMessage;

        this.muteNotifications(true, muteUpdate.userID, muteUpdate.sessionID);
        break;
      }
      case "Act:unMuteNotifications":{
        let muteUpdate = genericMessage;
        this.muteNotifications(false, muteUpdate.userID, muteUpdate.sessionID);
        break;
      }
      case "Act:UserNotifications":{
        this.parseNotifications(genericMessage);
        break;
      }
      case "Act:NotificationAdd":{
        this.addNotification(genericMessage);
        break;
      }
      case "Act:NotificationReceived":{
        this.markNotificationReceived(genericMessage);
        break;
      }
      case "SessionDump": {
        let sesDump = genericMessage;
        this.anonSessionDumped = true;
        //console.log(sesDump);
        this.parseSessions(sesDump.Sessions);
        break;
      }
      case "ProfileDump": {
        let profDump: intProfDump = genericMessage;
        this.parseProfiles(profDump.Profiles);
        break;
      }
      case "ActiveUsers": {
        let activeUsers: intActiveUsers = genericMessage;
       // console.log(activeUsers);
        this.updateActiveUsers(activeUsers.SessionID, activeUsers.users);
        break;
      }
      case "Act:ActiveUsers":{
        let activeUsers: intActiveUsers = genericMessage;
       // console.log(activeUsers);
        this.updateActiveUsers(activeUsers.SessionID, activeUsers.users);
        break;
      }
      case "Act:RefreshToken":{
        window.localStorage.setItem('refreshToken', genericMessage.RefreshToken);
        break;
      }
      case "SessionUpdate":{
        let sessionUpdate: intSessionUpdate = genericMessage;   
       // console.log('session updated');   
        this.updateSession(sessionUpdate.Session);
        break;
      }
      

    }
      
  return{
    author: authorData,
    message: genericMessage.MsgType
  }
   }

   ngAfterViewInit(){
    
   }


   getPhoneContacts(){
    // console.log('logging contacts, length: ');
    let fields = [
      navigator.contacts.fieldType.displayName, 
      navigator.contacts.fieldType.familyName,
      navigator.contacts.fieldType.givenName,
      navigator.contacts.fieldType.emails,
    ];
    let options = {
      multiple: true,
      desiredFields: [
        navigator.contacts.fieldType.id,
        navigator.contacts.fieldType.displayName,
        navigator.contacts.fieldType.familyName,
        navigator.contacts.fieldType.givenName,
        navigator.contacts.fieldType.emails
      ],
      filter: ""
    };

    
    navigator.contacts.find(fields, 
      (contacts=>{
        for(let i=0;i<=contacts.length-1;i++){
          let contact = contacts[i];
          let newPerson = new Person();

          if(typeof(contact.emails)=='undefined' || typeof(contact.emails[0]) == 'undefined'){
            continue;
          }

          if(this.checkPersonPresent(contact.emails[0].value) || contact.emails[0].value==null){
           // console.log('Phone contact already present or no email');
            continue;
          }

          if(contact.displayName.indexOf(' ')>0){
            newPerson.firstname = contact.displayName.substr(0,contact.displayName.indexOf(' '));
            newPerson.lastname = contact.displayName.substr(contact.displayName.indexOf(' '));
          }else{
            newPerson.firstname = contact.displayName;
          }


          newPerson.email = contact.emails[0].value;
          newPerson.username = 'googleInvite';
        //  console.log(newPerson);
          this._persons.push(newPerson);
        }
    }),
    ((err)=>{
     // console.log(err);
    }), options);
     
   }

   getContacts(token){
     
    gapi.client.request({
      method: 'GET',
      path: '/m8/feeds/contacts/default/full?alt=json&max-results=30000',
      headers: {
              "GData-Version": "3.0",
              "data":token,
      }
      })
    .then((data)=>{
      this.fillContacts(data);
    });
    
   }

   gapiAuth(token){
     
    if(!gapi.client || this.gapiFilled){
      return;
    }

    gapi.client.setApiKey('AIzaSyCMxrTOjmJhay-fmFn9kJ0jt7ZMoMIOuqE');
    gapi.client.setToken({access_token: token});

    gapi.client.request({
          method: 'GET',
          path: '/m8/feeds/contacts/default/full?alt=json&max-results=30000',
          headers: {
                  "GData-Version": "3.0",
                  "data":token,
          }
          })
      .then((data)=>{
        this.fillContacts(data);

        if(typeof gapi.client.people !== "undefined" ){
          return gapi.client.people.people.connections.list({
            'resourceName': 'people/me',
            'pageSize': 1000,
            'personFields': 'names,emailAddresses',
          }).then((response)=>this.fillPeople(response));
        }

      this.gapiFilled = true;
      }).catch((err)=>{
        window.localStorage.removeItem("googleToken")
      })

}

checkPersonPresent(email){
  for(let i = 0; i<=this._persons.length-1;i++){
    let person: Person = this._persons[i];
    if(person.email == email){
      return true;
    }
  }
  return false;
}

fillPeople(response){
  var connections = response.result.connections;
          

  if (connections.length > 0) {
    for (let i = 0; i < connections.length; i++) {
      var person = connections[i];
      if (person.names && person.names.length > 0) {
        if(person.emailAddresses){
          //console.log(person.names[0].familyName + " " + person.names[0].givenName + " " + person.emailAddresses[0].value);
        
          let newPerson: Person = new Person();
          newPerson.active = false;
          newPerson.lastname = person.names[0].familyName;
          newPerson.firstname = person.names[0].givenName;
          newPerson.email = person.emailAddresses[0].value;
          newPerson.username = 'googleInvite';
          if(!this.checkPersonPresent(newPerson.email)){
            this._persons.push(newPerson);
          }
          
        }


      } else {

      }
    }
  } else {
   
  }
}

public sortSessions(){

  this._sessions.sort(this.sessionCompare);

}

public sortRefreshSessions(){
  this._sessions.sort(this.sessionCompare);
}

fillContacts(data){
  let testData = JSON.parse(data.body);
  let lastETag = "";

  //console.log(JSON.stringify(testData));
  
  for(let i=1; i<= testData.feed.entry.length-1;i++){

    if(testData.feed.entry[i].gd$email && testData.feed.entry[i].gd$etag != lastETag){
      if(!testData.feed.entry[i].gd$name || this.checkPersonPresent(testData.feed.entry[i].gd$email[0].address)){
        continue;
      }
      let person: Person = new Person();
      person.active = false;
      if(testData.feed.entry[i].gd$name.gd$familyName!=null ){
        person.lastname = testData.feed.entry[i].gd$name.gd$familyName.$t;
      }
      if(testData.feed.entry[i].gd$name.gd$givenName ){
        person.firstname = testData.feed.entry[i].gd$name.gd$givenName.$t;
      }
      person.email = testData.feed.entry[i].gd$email[0].address;
      person.username = "googleInvite";

      //console.log(person.firstname + " " + person.lastname + " " + person.email);
      if(person.email != ""){
       
        this._persons.push(person);
      }
      
      
      lastETag = testData.feed.entry[i].gd$etag;
    }
    
  }
}



   public emptySessionData(){
    //this._wsService.closeConnection();
    this._sessions = new Array();
    this._persons = new Array();
    this._authState.next("None");
    this._wsState.next("None");
    this._refreshRequest = false;

 
    //this.messages.unsubscribe();
    localStorage.clear();
   }

   markNotificationReceived(notificationAct: intNotificationRec){
    for(let not=0; not<=this._notifications.length-1;not++){
      let notification: NotificationItem = this._notifications[not];
      if(notification.notificationID == notificationAct.notificationID){
        notification.received = true;
      }
    }
   }

   addNotification(message){
     if(message.userID == this.getCurrentUserID()){
       this.requestNotifications();
     }
   }

   setProfileSettings(message){
     this.profileSettings.firstname = message.Settings.firstname;
     this.profileSettings.lastname = message.Settings.lastname;
     this.profileSettings.email = message.Settings.email;
     this.profileSettings.color = message.Settings.color;
     this.profileSettings.pushComments = message.Settings.pushComments;
     this.profileSettings.pushOptions = message.Settings.pushOptions;
     this.profileSettings.pushInvites = message.Settings.pushInvites;
     this.profileSettings.pushPrompts = message.Settings.pushPrompts;
     this.profileSettings.pushDecisions = message.Settings.pushDecisions;
   //  console.log('profile settings parsed');
  //   console.log(this.profileSettings);
   }

   checkPushOff(){
    return  this.profileSettings.pushComments +
            this.profileSettings.pushDecisions +
            this.profileSettings.pushOptions +
            this.profileSettings.pushPrompts +
            this.profileSettings.pushInvites
  }

    

   parseNotifications(notificationsResponse){
     this._notifications.length = 0;
     if(!notificationsResponse.Notifications){
       return;
     }
     for(let not=0; not<=notificationsResponse.Notifications.length-1;not++){

       let notification: NotificationItem = new NotificationItem();

       if(notificationsResponse.Notifications[not][0]){
          notification.notificationID = notificationsResponse.Notifications[not][0];
       }

       if(notificationsResponse.Notifications[not][1]){
        notification.notificationType = notificationsResponse.Notifications[not][1];
      }
      if(notificationsResponse.Notifications[not][2]){
        notification.receiverID = notificationsResponse.Notifications[not][2];
      }
      if(notificationsResponse.Notifications[not][3]){
        notification.extraString = notificationsResponse.Notifications[not][3];
      }
      if(notificationsResponse.Notifications[not][4]){
        notification.received = notificationsResponse.Notifications[not][4];
      }
      if(notificationsResponse.Notifications[not][5]){
        notification.originatorID = notificationsResponse.Notifications[not][5];
      }
      if(notificationsResponse.Notifications[not][6]){
        notification.sessionID = notificationsResponse.Notifications[not][6];
      }
      if(notificationsResponse.Notifications[not][7]){
        notification.extraInt = notificationsResponse.Notifications[not][7];
      }
      if(notificationsResponse.Notifications[not][8]){
        notification.createdOn = notificationsResponse.Notifications[not][8];
      }
      let session = this.findSession(notification.sessionID);
      if(session){
        this._notifications.push(notification);
      }
       
     }
   //  console.log(this._notifications);
   }

   openNotificationMenu(notificationID){
   //  console.log('opening menu');
    for(let not=0; not<=this._notifications.length-1;not++){
      let notification: NotificationItem = this._notifications[not];
      if(notification.notificationID == notificationID){
        notification.menuOpen = true;
      }else{
        notification.menuOpen = false;
      }
    }
   }

   closeAllNotificationMenus(){
  //   console.log('closing menus');
    for(let not=0; not<=this._notifications.length-1;not++){
      let notification: NotificationItem = this._notifications[not];
        notification.menuOpen = false;
    }
   }

   parseSession(newSession: Session){
    let addSession: Session = new Session();
    
    addSession.createdBy = newSession.createdBy;
    addSession.createdOn = newSession.createdOn;
    addSession.session_id = newSession.session_id;
    addSession.sessionName = newSession.sessionName;
   
    addSession.sessionStatus = newSession.sessionStatus;
    addSession.updatedOn = newSession.updatedOn + " UTC";

    //Decision Deserialization
    for(let dec of newSession.sessionDecisions){
      let newDecision = <Decision>dec;
      let addDecision: Decision = new Decision();
      addDecision.createdBy = newDecision.createdBy;
      addDecision.decisionId = newDecision.decisionId;
      addDecision.decisionName = newDecision.decisionName;
      
      addDecision.sessionId = newDecision.sessionId;

      //Option Deserialization
      if(newDecision.options && newDecision.options.length){
       for(let opt of newDecision.options){
          let newOption = <Option>opt;
          let addOption: Option = new Option();
          addOption.createdBy = newOption.createdBy;
          addOption.decisionId = newOption.decisionId;
          addOption.optionId = newOption.optionId;
          addOption.sessionId = newOption.sessionId;
          addOption.title = newOption.title;
          addOption.voteCount = newOption.voteCount;

          //ProfileVote Deserialization
          if(newOption.profileVotes && newOption.profileVotes.length){
            for(let pv of newOption.profileVotes){
              let newProfileVote = <Profilevote>pv;
              let addProfileVote: Profilevote = new Profilevote();
              addProfileVote.voteId = newProfileVote.voteId;
              addProfileVote.decisionId = newProfileVote.decisionId;
              addProfileVote.optionId = newProfileVote.optionId;
              addProfileVote.profileId = newProfileVote.profileId;
              addProfileVote.sessionId = newProfileVote.sessionId;
              addOption.profileVotes.push(newProfileVote);
            }
          }

          addDecision.options.unshift(addOption);

        }
      }
      //FinishVote Deserialization
      if(newDecision.finishVotes && newDecision.finishVotes.length){
        for(let fv of newDecision.finishVotes){
          let newFinishVote = <FinishVote>fv;
          let addFinishVote: FinishVote = new FinishVote();
          addFinishVote.decisionId = newFinishVote.decisionId;
          addFinishVote.profileId = newFinishVote.profileId;
          addFinishVote.sessionId = newFinishVote.sessionId

          addDecision.finishVotes.push(addFinishVote);
        }
      }
      addSession.sessionDecisions.push(addDecision);
    }
          //DecisionFinished Deserialization
          if(newSession.sessionDecisionFinished && newSession.sessionDecisionFinished.length){
            for(let df of newSession.sessionDecisionFinished){
              let newDecisionFinished = <DecisionFinished>df;
              addSession.sessionDecisionFinished.push(newDecisionFinished);
            }
          }
      //Persons Deserialization
      if(newSession.sessionPersons && newSession.sessionPersons.length){
        for(let sp of newSession.sessionPersons){
          let newSessionPerson = <Person>sp;
          let addSessionPerson: Person = new Person();
          addSessionPerson.createddate = newSessionPerson.createddate;
          addSessionPerson.email = newSessionPerson.email;
          addSessionPerson.firstname = newSessionPerson.firstname;
          addSessionPerson.lastname = newSessionPerson.lastname;
          addSessionPerson.userimage = newSessionPerson.userimage;
          addSessionPerson.username = newSessionPerson.username;
          addSessionPerson.active = newSessionPerson.active;
            addSessionPerson.muted = newSessionPerson.muted;
          addSessionPerson.excluded = newSessionPerson.excluded;



          addSession.sessionPersons.push(addSessionPerson);
        }
        addSession.sessionPersons.sort(this.personCompare);
      }
                      //Messages Deserialization
                      if(newSession.sessionMessages && newSession.sessionMessages.length){
                        for(let sm of newSession.sessionMessages){

                          let newSessionMessage = <SujjestMessage>sm;
                          
      
                          addSession.sessionMessages.push(newSessionMessage);
                        }
                      }
        return addSession;
   }

   parseSessions(sessions: Session[]){
                 //Root Session Deserialization

                if(!sessions){
                  this.requestProfiles(this.getCurrentUserID());
                  this.requestColorChange(2);
                  this.emptySessions = true;
                  return;
                }
                sessions.sort(this.sessionCompare);
                //this._sessions.length =0;
                
                 for(let ses=0;ses<=sessions.length-1;ses++){
                  
                  

                  let newSession = <Session>sessions[ses];

                  let sessionToUpdate = this.findSession(newSession.session_id);
                  if(sessionToUpdate){
                    this.updateSession(newSession);
                    continue;
                  }

                  let addSession: Session = new Session();
    
                  addSession.createdBy = newSession.createdBy;
                  addSession.createdOn = newSession.createdOn;
                  addSession.session_id = newSession.session_id;
                  addSession.sessionName = newSession.sessionName;
                 
                  addSession.sessionStatus = newSession.sessionStatus;
                  addSession.updatedOn = newSession.updatedOn + " UTC";

                  //Decision Deserialization
                  for(let dec of newSession.sessionDecisions){
                    let newDecision = <Decision>dec;
                    let addDecision: Decision = new Decision();
                    addDecision.createdBy = newDecision.createdBy;
                    addDecision.decisionId = newDecision.decisionId;
                    addDecision.decisionName = newDecision.decisionName;
                    
                    addDecision.sessionId = newDecision.sessionId;
    
                    //Option Deserialization
                    if(newDecision.options && newDecision.options.length){
                     for(let opt of newDecision.options){
                        let newOption = <Option>opt;
                        let addOption: Option = new Option();
                        addOption.createdBy = newOption.createdBy;
                        addOption.decisionId = newOption.decisionId;
                        addOption.optionId = newOption.optionId;
                        addOption.sessionId = newOption.sessionId;
                        addOption.title = newOption.title;
                        addOption.voteCount = newOption.voteCount;
      
                        //ProfileVote Deserialization
                        if(newOption.profileVotes && newOption.profileVotes.length){
                          for(let pv of newOption.profileVotes){
                            let newProfileVote = <Profilevote>pv;
                            let addProfileVote: Profilevote = new Profilevote();
                            addProfileVote.voteId = newProfileVote.voteId;
                            addProfileVote.decisionId = newProfileVote.decisionId;
                            addProfileVote.optionId = newProfileVote.optionId;
                            addProfileVote.profileId = newProfileVote.profileId;
                            addProfileVote.sessionId = newProfileVote.sessionId;
                            addOption.profileVotes.push(newProfileVote);
                          }
                        }
    
                        addDecision.options.unshift(addOption);
      
                      }
                    }
                    //FinishVote Deserialization
                    if(newDecision.finishVotes && newDecision.finishVotes.length){
                      for(let fv of newDecision.finishVotes){
                        let newFinishVote = <FinishVote>fv;
                        let addFinishVote: FinishVote = new FinishVote();
                        addFinishVote.decisionId = newFinishVote.decisionId;
                        addFinishVote.profileId = newFinishVote.profileId;
                        addFinishVote.sessionId = newFinishVote.sessionId
    
                        addDecision.finishVotes.push(addFinishVote);
                      }
                    }
                    addSession.sessionDecisions.push(addDecision);
                  }
                        //DecisionFinished Deserialization
                        if(newSession.sessionDecisionFinished && newSession.sessionDecisionFinished.length){
                          for(let df of newSession.sessionDecisionFinished){
                            let newDecisionFinished = <DecisionFinished>df;
                            addSession.sessionDecisionFinished.push(newDecisionFinished);
                          }
                        }
                    //Persons Deserialization
                    if(newSession.sessionPersons && newSession.sessionPersons.length){
                      for(let sp of newSession.sessionPersons){
                        let newSessionPerson = <Person>sp;
                        let addSessionPerson: Person = new Person();
                        addSessionPerson.createddate = newSessionPerson.createddate;
                        addSessionPerson.email = newSessionPerson.email;
                        addSessionPerson.firstname = newSessionPerson.firstname;
                        addSessionPerson.lastname = newSessionPerson.lastname;
                        addSessionPerson.userimage = newSessionPerson.userimage;
                        addSessionPerson.username = newSessionPerson.username;
                        addSessionPerson.active = newSessionPerson.active;
                          addSessionPerson.muted = newSessionPerson.muted;
                        addSessionPerson.excluded = newSessionPerson.excluded;

    
    
                        addSession.sessionPersons.push(addSessionPerson);
                      }
                      addSession.sessionPersons.sort(this.personCompare);
                    }
                                    //Messages Deserialization
                                    if(newSession.sessionMessages && newSession.sessionMessages.length){
                                      for(let sm of newSession.sessionMessages){

                                        let newSessionMessage = <SujjestMessage>sm;
                                        
                    
                                        addSession.sessionMessages.push(newSessionMessage);
                                      }
                                    }
    
                  this._sessions.push(addSession);
                }
          if(typeof cordova !== "undefined"){       this.sortSessions()     }

    //    console.log('done parsing sessions');
        this.requestProfiles(this.getCurrentUserID());
   }

   sessionCompare(session1:Session, session2:Session){
     if(session1.updatedOn > session2.updatedOn){
       return -1;
     }
     if(session1.updatedOn < session2.updatedOn){
       return 1;
     }
     return 0;
   }

   personCompare(person1:Person, person2:Person){
     //console.log(person1.createddate);
    if(person1.createddate < person2.createddate){
       return -1;
    }
    if(person1.createddate > person2.createddate){
      return 1;
    }
    return 0;
  }

   parseProfiles(profiles: Person[]){
    this._persons.length = 0;
    if(profiles){
      //console.log(profiles);
      for(let pers of profiles){
        let newPers = <Person>pers;
        newPers.active = true;
        newPers.color = pers.color;

        this._persons.push(newPers);
        if(newPers.username == this.getCurrentUserID()){
          this.setProfileColor(newPers.color);
        }
      }
      this.loading = false;
    }

    
    if(typeof cordova !== 'undefined' ){
      var permissions = cordova.plugins.permissions;
      let hasContactReadPerm;
      permissions.hasPermission(permissions.READ_CONTACTS, ( status )=>{
        if( status.hasPermission ){
          this.getPhoneContacts();
        }else{

        }
      });

      
    }
    
    this._wsState.next("Complete");
    if(this.uId.length>15){
      this.requestNotifications();
    }

    this.requestProfileSettings();
   }

   

   

   private updateSession(sessionUpdate: Session){

    let session: Session = new Session();
    let userPresent = false;



    for(let per=0;per<=sessionUpdate.sessionPersons.length-1; per++){
      let person = sessionUpdate.sessionPersons[per];
      if(person.username == this.getCurrentUserID()){
        userPresent = true;
      }
    }

    if(!userPresent){
      //Ignore session as user is not in it.
      return;
    }

    session = this.findSession(sessionUpdate.session_id);
    //console.log(session);
    if(!session){
    //  console.log('adding session to session list');
    //  console.log(sessionUpdate);
      let parsedSession = this.parseSession(sessionUpdate);
    //  console.log(sessionUpdate);
    //  console.log(parsedSession);
      this._sessions.push(parsedSession);
            this.sortSessions(); 
      this.requestProfiles(this.getCurrentUserID());
      return;
    }

      session.createdBy = sessionUpdate.createdBy;
      session.createdOn = sessionUpdate.createdOn;
      session.session_id = sessionUpdate.session_id;
      session.sessionName = sessionUpdate.sessionName;
    
      session.sessionStatus = sessionUpdate.sessionStatus;
      session.updatedOn = sessionUpdate.updatedOn + " UTC";

      session.sessionDecisions.length = 0;
      //Decision Deserialization
      for(let dec of sessionUpdate.sessionDecisions){
        let newDecision = <Decision>dec;
        let addDecision: Decision = new Decision();
        addDecision.createdBy = newDecision.createdBy;
        addDecision.decisionId = newDecision.decisionId;
        addDecision.decisionName = newDecision.decisionName;
        
        addDecision.sessionId = newDecision.sessionId;

        //Option Deserialization
        if(newDecision.options && newDecision.options.length){
        for(let opt of newDecision.options){
            let newOption = <Option>opt;
            let addOption: Option = new Option();
            addOption.createdBy = newOption.createdBy;
            addOption.decisionId = newOption.decisionId;
            addOption.optionId = newOption.optionId;
            addOption.sessionId = newOption.sessionId;
            addOption.title = newOption.title;
            addOption.voteCount = newOption.voteCount;

            //ProfileVote Deserialization
            if(newOption.profileVotes && newOption.profileVotes.length){
              for(let pv of newOption.profileVotes){
                let newProfileVote = <Profilevote>pv;
                let addProfileVote: Profilevote = new Profilevote();
                addProfileVote.voteId = newProfileVote.voteId;
                addProfileVote.decisionId = newProfileVote.decisionId;
                addProfileVote.optionId = newProfileVote.optionId;
                addProfileVote.profileId = newProfileVote.profileId;
                addProfileVote.sessionId = newProfileVote.sessionId;
                addOption.profileVotes.push(newProfileVote);
              }
            }

            addDecision.options.unshift(addOption);

          }
        }
        //FinishVote Deserialization
        if(newDecision.finishVotes && newDecision.finishVotes.length){
          for(let fv of newDecision.finishVotes){
            let newFinishVote = <FinishVote>fv;
            let addFinishVote: FinishVote = new FinishVote();
            addFinishVote.decisionId = newFinishVote.decisionId;
            addFinishVote.profileId = newFinishVote.profileId;
            addFinishVote.sessionId = newFinishVote.sessionId

            addDecision.finishVotes.push(addFinishVote);
          }
        }
        session.sessionDecisions.push(addDecision);
      }
      session.sessionDecisionFinished.length =0;
            //DecisionFinished Deserialization
      if(sessionUpdate.sessionDecisionFinished && sessionUpdate.sessionDecisionFinished.length){
        for(let df of sessionUpdate.sessionDecisionFinished){
          let newDecisionFinished = <DecisionFinished>df;
          session.sessionDecisionFinished.push(newDecisionFinished);
        }
      }
      session.sessionPersons.length = 0;
      //Persons Deserialization
      if(sessionUpdate.sessionPersons && sessionUpdate.sessionPersons.length){
        for(let sp of sessionUpdate.sessionPersons){
          let newSessionPerson = <Person>sp;
          let addSessionPerson: Person = new Person();
          addSessionPerson.createddate = newSessionPerson.createddate;
          addSessionPerson.email = newSessionPerson.email;
          addSessionPerson.firstname = newSessionPerson.firstname;
          addSessionPerson.lastname = newSessionPerson.lastname;
          addSessionPerson.userimage = newSessionPerson.userimage;
          addSessionPerson.username = newSessionPerson.username;
          addSessionPerson.active = newSessionPerson.active;
          addSessionPerson.excluded = newSessionPerson.excluded;

            addSessionPerson.muted = newSessionPerson.muted;


          session.sessionPersons.push(addSessionPerson);
        }

        session.sessionPersons.sort(this.personCompare);
      }
      session.sessionMessages.length = 0;
      //Messages Deserialization
      if(sessionUpdate.sessionMessages && sessionUpdate.sessionMessages.length){
        for(let sm of sessionUpdate.sessionMessages){

          let newSessionMessage = <SujjestMessage>sm;
          

          session.sessionMessages.push(newSessionMessage);
        }
      }


/*
      if(session){
        session = addSession;
      }else{
        this._sessions.unshift(addSession);
      }
*/
this.sortSessions();


   }

   public sendAuth(userKey: string, deviceKey: string){

    let anonUser = window.localStorage.getItem("AnonUserID");
    
      let authResponse = new AuthResponse();
if(anonUser !== ''){
  authResponse.anonUser = anonUser;
}
      authResponse.userID = userKey;
      authResponse.deviceID = deviceKey;
      let response = JSON.stringify(authResponse);
      this._wsService.sendMessage(response);

   }

   public sendAnonAuth(token: string, sessionID: number){
     let anonResponse = new AuthResponse();
     anonResponse.userID = token;
     anonResponse.sessionID = sessionID;
     let response = JSON.stringify(anonResponse);
     this._wsService.sendMessage(response);
   }

   public requestRefresh(){
     if(!this.paused){
      this._refreshRequest = true;
      let request = new WsRefreshRequest();
       request.userID = this.getCurrentUserID();
       request.updateTime = this.pauseTime;
   //   console.log(this.pauseTime);
      let jsonMessage = JSON.stringify(request);
      this._wsService.sendMessage(jsonMessage);
      this.pauseTime = new Date('August 19, 1975 23:15:30');
      this.loading = true;
     }

   }

   public requestNotifications(){
    let request = {
      MsgType: "Req:UserNotifications",
      userID: this.getCurrentUserID()
    }
    this._wsService.sendMessage(JSON.stringify(request))
   }

   public requestNotificationReceived(notificationID: number){
     let request = {
       MsgType: "Req:NotificationReceived",
       notificationID: notificationID,
       userID: this.getCurrentUserID()
     }
     this._wsService.sendMessage(JSON.stringify(request));
   }

   public requestActiveUsers(sessionID: number){
    let request = {
      MsgType: "Req:ActiveUsers",
      userID: this.getCurrentUserID(),
      sessionID: sessionID,
    }
    this._wsService.sendMessage(JSON.stringify(request))
   }

   public requestProfileCheck(firstName, lastName, email, userName){
    let person:Person = new Person();
    person.active = true;
    person.firstname = firstName;
    person.lastname = lastName;
    person.email = email;
    person.username = userName;

     let request = {
       MsgType: "Req:UserCheck",
       UserId: userName,
       email: email,
       firstName: firstName,
       lastName:lastName
     }
     
     this._wsService.sendMessage(JSON.stringify(request));
     //this._persons.push(person);
   }

   public requestProfileSettings(){
     let request = {
       MsgType: "Req:ProfileSettings",
       userID: this.getCurrentUserID()
     }

     this._wsService.sendMessage(JSON.stringify(request));
   }

   public requestProfileSettingsUpdate(){
      let firstname = this.profileSettings.firstname;
      let lastname = this.profileSettings.lastname;
      let areacode = this.profileSettings.areacode;
      let phone = this.profileSettings.phone;
      let email = this.profileSettings.email;
      let color = this.profileSettings.color;
      let pushComments = this.profileSettings.pushComments;
      let pushOptions = this.profileSettings.pushOptions;
      let pushInvites = this.profileSettings.pushInvites;
      let pushPrompts = this.profileSettings.pushPrompts;
      let pushDecisions = this.profileSettings.pushDecisions;
      
      let request = {
        MsgType: "Req:UpdateProfileSettings",
        userID: this.getCurrentUserID(),
        firstname: firstname,
        lastname: lastname,
        areacode: areacode,
        phone: phone,
        email: email,
        color: color,
        pushComments: pushComments,
        pushOptions: pushOptions,
        pushInvites: pushInvites,
        pushPrompts: pushPrompts,
        pushDecisions: pushDecisions
      }

      this._wsService.sendMessage(JSON.stringify(request));
   }

   public requestMuteNotifications(mute: boolean, sessionId: number){
    let muteType = "Req:unMuteNotifications";
    if(mute){
      muteType = "Req:MuteNotifications";
    } 
    
    let request = {
       MsgType: muteType,
       userID: this.getCurrentUserID(),
       sessionID: sessionId
     }
    this._wsService.sendMessage(JSON.stringify(request));
   }

   private updateActiveUsers(sessionID: number, Persons: Person[]){
    let session: Session = this.findSession(sessionID);
    
    if(session){
      session.clearUsersActiveStatus();
      for(let i=0; i<=Persons.length-1; i++){
        let person: Person = Persons[i];
        if(person.active){
          session.setUserActive(person.username);
        }
      }
    }

   
   }

   public getSessions(): Observable<Session[]> {
     return of(this._sessions);
   }

   public getPersons(): Observable<Person[]> {
    
     return  of(this._persons)
   }

   public getPerson(profileId){
     for(let per=0; per<=this._persons.length-1; per++){
       let person: Person = this._persons[per];
       if(person.username == profileId){
        return person;
       }
     }
   }

   public requestColorChange(colorId: number){
     if(this.getCurrentUserID()){
      let request = <intColorReq>{
        MsgType: "Req:ProfileColor",
        userID: this.getCurrentUserID(),
        color: colorId
      }
      //console.log("color message request" + JSON.stringify(request));
      this._wsService.sendMessage(JSON.stringify(request));
     }
   }

   public requestDecision(decisionTitle: string, SessionId: number, userId: string){
    if(typeof FirebasePlugin !== "undefined"){
      FirebasePlugin.logEvent("decision_action", { action: 'add', type: 'prompt'});
    }
    decisionTitle = decisionTitle.replace(/\r?\n/g, ' ');
    let request = <intDecRequest> {
      MsgType: "Req:DecAdd",
      userID: userId,
      sessionID: SessionId,
      DecisionName: decisionTitle
    }
    this._wsService.sendMessage(JSON.stringify(request))
   }

   public requestDecisionFinish(SessionId: number, DecisionId: number, OptionId: number, userId: string){

    let request = <intDecFinishReq> {
      MsgType: "Req:DecFinish",
      userID: userId,
      sessionID: SessionId,
      decisionID: DecisionId,
      optionID: OptionId
    }
    this._wsService.sendMessage(JSON.stringify(request));
   }

   public requestLeaveSession(SessionId: number, userId: string){
    let request = <intLeaveSesReq> {
      MsgType: "Req:LeaveSession",
      userID: userId,
      sessionID: SessionId,
      inviteRemove: false
    }
    this._wsService.sendMessage(JSON.stringify(request));
   }

   public requestProfiles(userId: string){
     let request = <intProfilesRequest> {
       MsgType: "Req:ProfileDump",
       userID: userId
     }
      this._wsService.sendMessage(JSON.stringify(request));
   }

   public requestProfileUpdate(userId: string, firstName: string, lastName: string){
    if(typeof FirebasePlugin !== "undefined"){
      FirebasePlugin.logEvent("profile_action", { action: 'add', type: 'setting'});
    }
      let request = {
        MsgType: "Req:ProfileUpdate",
        userID: userId,
        firstName: firstName,
        lastName: lastName
      };
      this._wsService.sendMessage(JSON.stringify(request));
   }

   public requestNewSession(sessionTitle: string, userId: string){
    if(typeof FirebasePlugin !== "undefined"){
      FirebasePlugin.logEvent("session_action", { action: 'add', type: 'session'});
    }
     sessionTitle = sessionTitle.replace(/\r?\n/g, ' ');
    let request = <intNewSessionReq> {
      MsgType: "Req:SesAdd",
      sessionName: sessionTitle,
      userID: userId
    }
    //console.log(JSON.stringify(request));
    this._wsService.sendMessage(JSON.stringify(request));
   }

   public requestSessionProfileInvite(sessionId: number, profileId: string, newEmailAddress: string, callingUserID: string,
    firstName: string, lastName: string){
      let request = {
        MsgType: "Req:UserAdd",
        callingUserID: callingUserID,
        sessionID: sessionId,
        userID: profileId,
        userName: newEmailAddress,
        email: newEmailAddress,
        inviteFirst: firstName,
        inviteLast: lastName,
      }
      this._wsService.sendMessage(JSON.stringify(request));

      for(let per=0; per<=this._persons.length-1;per++){
        let person: Person = this._persons[per];
        if(person.username==profileId){
          this._persons.splice(per, 1);
          this._persons.unshift(person);
        }
      }

    }

   public requestSessionProfile(sessionId: number, profileId: string, newEmailAddress: string, callingUserID: string){
    console.log(profileId);
    let request = <intSesProfileReq> {
      MsgType: "Req:UserAdd",
      callingUserID: callingUserID,
      sessionID: sessionId,
      userID: profileId,
      userName: newEmailAddress,
      email: newEmailAddress
    }
    this._wsService.sendMessage(JSON.stringify(request));

    for(let per=0; per<=this._persons.length-1;per++){
      let person: Person = this._persons[per];
      if(person.username==profileId){
        this._persons.splice(per, 1);
        this._persons.unshift(person);
      }
    }

   }

   public requestAnonUser(name, sessionId, email){
    let request = {
      MsgType: "Req:CreateAnonUser",
      Name: name,
      sessionID: sessionId,
      email: email,
    }
    if(name.trim() !== ''){
    this._wsService.sendMessage(JSON.stringify(request));
    window.localStorage.setItem("AnonName", name);
    window.localStorage.setItem("AnonSes", sessionId);
    window.localStorage.setItem("AnonEmail", email);
    }

  }

   public requestNewOption(sessionId: number, decisionId: number, optionTitle: string, userId: string){
    if(typeof FirebasePlugin !== "undefined"){
      FirebasePlugin.logEvent("option_action", { action: 'add', type: 'option'});
    }
    let request = <intNewOptionReq> {
      MsgType: "Req:OptAdd",
      decisionID: decisionId,
      OptionTitle: optionTitle,
      sessionID: sessionId,
      userID: this.getCurrentUserID()
    }
    this._wsService.sendMessage(JSON.stringify(request));
   }

   public requestUpFinishVote(sessionId: number, decisionId: number, userId: string){
    if(typeof FirebasePlugin !== "undefined"){
      FirebasePlugin.logEvent("finish_action", { action: 'add', type: 'vote'});
    }
    let request = <intFinishReq> {
      MsgType: "Req:upFinish",
      decisionID: decisionId,
      sessionID: sessionId,
      userID: this.getCurrentUserID()
    }
    this._wsService.sendMessage(JSON.stringify(request));
   }

   public requestDownFinishVote(sessionId: number, decisionId: number, userId: string){
    if(typeof FirebasePlugin !== "undefined"){
      FirebasePlugin.logEvent("finish_action", { action: 'remove', type: 'vote'});
    }
     //console.log(sessionId);
    let session = this.findSession(sessionId);
    let decision = session.getDecision(decisionId);
    
      let request = <intFinishReq> {
        MsgType: "Req:downFinish",
        decisionID: decisionId,
        sessionID: sessionId,
        userID: this.getCurrentUserID()
      }
      this._wsService.sendMessage(JSON.stringify(request));
    
    

   }

   public requestDownOptionVote(sessionId: number, decisionId: number, optionId: number, userId: string){
    if(typeof FirebasePlugin !== "undefined"){
      FirebasePlugin.logEvent("option_action", { action: 'add', type: 'vote'});
    }
    let request = <intVoteOption> {
       MsgType: "Req:downVoteOption",
       decisionID: decisionId,
       optionID: optionId,
       sessionID: sessionId,
       userID: this.getCurrentUserID()
     }

     this._wsService.sendMessage(JSON.stringify(request));
   }

   public requestUpOptionVote(sessionId: number, decisionId: number, optionId: number, userId: string){
    if(typeof FirebasePlugin !== "undefined"){
      FirebasePlugin.logEvent("option_action", { action: 'remove', type: 'vote'});
    }
    let session = this.findSession(sessionId);
    let decision = session.getDecision(decisionId);
    if(!decision.checkOptionVoted(optionId, userId)){
      let request = <intVoteOption> {
        MsgType: "Req:upVoteOption",
        decisionID: decisionId,
        optionID: optionId,
        sessionID: sessionId,
        userID: this.getCurrentUserID()
      }
  
       this._wsService.sendMessage(JSON.stringify(request));
    }
    
   }

   public requestNotifcationMute(userId){
     let request = <intNotifcationUpdate> {
       MsgType: "Req:MuteNotifications",
       userID: userId
     }

     this._wsService.sendMessage(JSON.stringify(request));
   }

   public requestSessionUpdate(sessionId: number){
    console.log('session update request')
    let request = {
       MsgType: "Req:SessionUpdate",
       userID: this.getCurrentUserID(),
       sessionID: sessionId
     }
     this._wsService.sendMessage(JSON.stringify(request));
   }

   public requestConvertInvite(userId, tokenId, sessionId){
     let request = {
      MsgType: "Req:ConvertInviteToken",
      userID: userId,
      tokenID: tokenId,
      sessionID: sessionId
     }
     this._wsService.sendMessage(JSON.stringify(request));
   }

   public requestSessionMessage(sessionId: number, messageText: string, userId: string){
    if(typeof FirebasePlugin !== "undefined"){
      FirebasePlugin.logEvent("chat_action", { action: 'add', type: 'message'});
    }
    let request = <intSesMesReq> {
      MsgType: "Req:sessionMessage",
      sessionID: sessionId,
      userID: this.getCurrentUserID(),
      messageData: messageText
    }
    this._wsService.sendMessage(JSON.stringify(request));
   }

   private updateProfile(userId: string, firstName: string, lastName: string){

     //console.log('profile updating');
     for(let per=0; per<=this._persons.length-1;per++){
       let person: Person = this._persons[per];
       if(person.username == userId){
         person.firstname = firstName;
         person.lastname = lastName;
       }
     }
     for(let ses=0; ses<=this._sessions.length-1;ses++){
       let session: Session = this._sessions[ses];
       for(let sesPer=0; sesPer<=session.sessionPersons.length-1;sesPer++){
         let person: Person = session.sessionPersons[sesPer];
         if(person.username == userId){
           person.firstname = firstName;
           person.lastname = lastName;
         }
       }
     }
   }

   private muteNotifications(mute: boolean, userID: string, sessionId: number){
    let session = this.findSession(sessionId);
    
    for(let per=0; per<=session.sessionPersons.length-1;per++){
      let person: Person = session.sessionPersons[per];
      if(person.username == userID){
        person.muted = mute;
      }
    }
   }

   private finishDecision(decFin: intDecFinish){
    //Update decision with transition details first
    let session = this.findSession(decFin.sessionID);
    let decision = session.getDecision(decFin.decisionID);
    decision.finishingActiveUserIds = session.getActiveUsers();
    decision.finishingFinishVotes = session.sessionDecisions[0].finishVotes; 
    this.requestActiveUsers(decFin.sessionID);

      setTimeout(()=>{
        let decisionFinished = new DecisionFinished();
        decisionFinished.decisionId = decFin.decisionID;
        decisionFinished.optionId = decFin.optionID;
        decisionFinished.profileId = decFin.userID;
        decisionFinished.sessionId = decFin.sessionID;
    
        session.sessionDecisionFinished.push(decisionFinished);
        
        session.setSessionUpdatedNow();
      }, 4000);

   }

   private addSession(sesAdd: intAddSession){

    this.emptySessions = false;

    if(sesAdd.userID==this.getCurrentUserID()){
     
      let session = new Session();
      session.createdBy = sesAdd.userID;
      session.createdOn = Date();
      session.updatedOn = Date();
      session.session_id = sesAdd.sessionID;
      session.sessionName = sesAdd.SessionName;
      session.updatedOn = Date();
      session.expanded = true;
  
      let personLookup = this.getPerson(sesAdd.userID);
      let person = new Person();
      person.username = sesAdd.userID;
      person.color = personLookup.color;
      person.firstname = personLookup.firstname;
      person.lastname = personLookup.lastname;
      person.userimage = personLookup.userimage;
      person.email = personLookup.email;
      person.excluded = false;
      person.active = true;
      
      session.sessionPersons.push(person);
  
      let decision = new Decision();
      decision.decisionId = sesAdd.decisionID;
      decision.decisionName = sesAdd.SessionName;
      session.sessionDecisions.push(decision);
      session.setSessionUpdatedNow();
      this._sessions.unshift(session);
      this.sortSessions();
      session.expanded = true;

      this.sessionAddSubject.next(sesAdd.sessionID);



 

      //this.router.navigate(['session'], {queryParams: { session: sesAdd.sessionID}});

      //console.log("session added");
      //session.setSessionUpdatedNow();
      //    if(typeof FirebasePlugin !== "undefined"){       this.sortSessions()     }
    }


   }

   private addDecision(decAdd: intAddDecision){
     
    let session: Session = this.findSession(decAdd.sessionID);
    let decision: Decision = new Decision();
    //console.log(JSON.stringify(decAdd));
    decision.decisionId = decAdd.decisionID;
    decision.sessionId = decAdd.sessionID;
    decision.decisionName = decAdd.DecisionName;
    decision.createdBy = decAdd.userID;
    decision.finishVotes = session.sessionDecisions[0].finishVotes;




    session.sessionDecisions.push(decision);
    session.setSessionUpdatedNow();
        if(typeof cordova !== "undefined"){       this.sortSessions()     }
   }

   private removeUserFromSession(remUser: intRemoveUser){
    let session: Session = this.findSession(remUser.sessionID);

    if(remUser.userID === this.getCurrentUserID()){

      for(let ses=0; ses<=this._sessions.length-1;ses++){
        let rmSession: Session = this._sessions[ses];
        if(rmSession.session_id == remUser.sessionID){
          this._sessions.splice(ses,1);
        }
      }

      this.sortSessions();

      //this._sessions = this._sessions.filter(obj => obj !== session);
      for(let not=0; not<=this._notifications.length-1; not++){
        let notification: NotificationItem = this._notifications[not];
        if(notification.sessionID == session.session_id){
          this._notifications.filter(obj => obj !== notification);
        }
      }
      this._wsState.next('Filled');
    }else{
      if(remUser.inviteRemove){
        for(let pers of session.sessionPersons){
          if(pers.email===remUser.userID){
            session.sessionPersons = session.sessionPersons.filter(obj => obj !== pers);
          }
        }
        return;
      }

      for(let pers of session.sessionPersons){
        if(pers.username===remUser.userID){
          //console.log("removeWorks");
          session.sessionPersons = session.sessionPersons.filter(obj => obj !== pers);
          //remove option votes
          for(let dec=0;dec<=session.sessionDecisions.length-1;dec++){
            let decision:Decision = session.sessionDecisions[dec];
            for(let opt=0;opt<=decision.options.length-1;opt++){
              let option:Option = decision.options[opt];
              for(let pv=0;pv<=option.profileVotes.length-1;pv++){
                let profileVote:Profilevote = option.profileVotes[pv];
                if(profileVote.profileId==remUser.userID){
                  option.profileVotes = option.profileVotes.filter(obj => obj !== profileVote);
                }
              }
            }
          }

         // this.requestSessionUpdate(session.session_id);
        }
      }
      session.setSessionUpdatedNow();
    }


        if(typeof cordova !== "undefined"){       this.sortSessions()     }
    
   }



   private addOption(addOpt: intAddOption){
     console.log('adding option');
    let session: Session = this.findSession(addOpt.sessionID);
    for(let dec of session.sessionDecisions){
      if(dec.decisionId == addOpt.decisionID){
        let option: Option = new Option();
        option.createdBy = addOpt.userID;
        option.decisionId = addOpt.decisionID;
        option.optionId = addOpt.optionID;
        option.sessionId = addOpt.sessionID;
        option.title = addOpt.OptionTitle;
        
        let profileVote: Profilevote = new Profilevote();
        profileVote.decisionId = addOpt.decisionID;
        profileVote.optionId = addOpt.optionID;
        profileVote.sessionId = addOpt.sessionID;
        profileVote.profileId = addOpt.userID;

        option.profileVotes.push(profileVote);

        dec.options.unshift(option);
        for(let opt of dec.options){
          if(opt.optionId == addOpt.optionID){
            opt.animateOption(10);
            if(opt.createdBy == this.getCurrentUserID()){                
              opt.animateOption(this.getProfileColor());
            }else{
              opt.animateOption(10);
            }


            this.orderSessionPersonsVote(opt);
          } else {
            opt.animationState = "inactive";
          }
        }
      }
    }
    session.setSessionUpdatedNow();

          if(typeof cordova !== "undefined"){
                   this.sortSessions()
                       }

   }


   private upVoteOption(upVote: intVoteOption){
    let session: Session = this.findSession(upVote.sessionID);
    
    for(let dec of session.sessionDecisions){
      /*Clear finish color */
      //dec.animateFinish(0);
      if(dec.decisionId == upVote.decisionID){
        for(let opt of dec.options){
          if(opt.optionId == upVote.optionID){
            let profileVote: Profilevote = new Profilevote();
            profileVote.decisionId = upVote.decisionID;
            profileVote.optionId = upVote.optionID;
            profileVote.sessionId = upVote.sessionID;
            profileVote.profileId = upVote.userID;
            opt.profileVotes.push(profileVote);
            opt.voteCount++
            

            
            if(session.checkUserVotedOption(this.getCurrentUserID(), dec.decisionId, opt.optionId)){                
              opt.animateOption(this.getProfileColor());
            }else{
              opt.animateOption(10);
            }

            this.orderSessionPersonsVote(opt);
          } else {
            opt.animationState = "inactive";
          }
        }
      }
    }
    this.requestActiveUsers(upVote.sessionID);
    session.setSessionUpdatedNow();
        if(typeof cordova !== "undefined"){
                 this.sortSessions()     
                }
   }



   orderSessionPersonsFinish(sessionId: number, color: number){
    let session = this.findSession(sessionId)
     let newSessionPersons: Person[] = new Array();
     let voteCount = 0;
     clearTimeout(this.timer);
     for (let pers of session.sessionPersons){
      pers.visualColor = "inactive";
      if(session.checkFinishVote(pers.username)){
        pers.visualVoted = true;
        newSessionPersons.unshift(pers);
        voteCount++;
      }
    }
    for (let pers of session.sessionPersons){
      if(!session.checkFinishVote(pers.username)){
        pers.visualVoted = false;
        newSessionPersons.push(pers);
      }
    }
    session.sessionPersons = newSessionPersons;
     if(voteCount==0){
      for(let pers of newSessionPersons){
        session.emptyProfileHighlight(pers.username);
      }
     }else {
      let votedCount=0;
      for(let per=0; per<=session.sessionPersons.length-1;per++){
        let pers: Person = session.sessionPersons[per];
        session.emptyProfileHighlight(pers.username);



        if(pers.visualVoted){
          if(voteCount ==1){
            pers.setVisualOnly();
          } else if(votedCount ==0){
            pers.setVisualLeft();
          }else if(votedCount == voteCount-1){
            pers.setVisualRight();
          }else{
            pers.setVisualMiddle();
          }
          session.personHighlight(pers.username, color);

          this.timer = setTimeout(() => {
            for (let pers of session.sessionPersons){
             pers.visualLeft = false;
             pers.visualMiddle = false;
             pers.visualOnly = false;
             pers.visualRight = false;
             pers.visualColor = "inactive";
             pers.green = false;
             pers.pink = false;
             pers.lightgreen = false;
             pers.orange = false;
             pers.purple = false;
             pers.blue = false;

             session.sessionPersons.sort(this.personCompare);
            }
            //session.sessionPersons = newSessionPersons;
           }, 10000);
          votedCount++;
        }

      }

     }
     
   }

   orderSessionPersonsVote(opt: Option){
     let session = this.findSession(opt.sessionId);
     let newSessionPersons: Person[] = new Array();
     let votedPersons: Person[] = new Array();
     let unvotedPersons: Person[] = new Array();
     let voteCount = 0;
     let animationState = opt.animationState;
     clearTimeout(this.timer);
     for (let pers of session.sessionPersons){
      let voted = false; 
      
      for(let vote of opt.profileVotes){
        pers.visualColor = "inactive";
         if(pers.username == vote.profileId){
           pers.visualVoted = true;
           votedPersons.push(pers);
           voted = true;
           voteCount++;
         }
       }
       if (!voted){
         pers.visualVoted = false;
         unvotedPersons.push(pers);
       }
     }

     votedPersons.sort(this.personCompare);
     unvotedPersons.sort(this.personCompare);
     votedPersons.reverse();
     for(let vp=0; vp<=votedPersons.length-1;vp++){
       let votedPerson:Person = votedPersons[vp];
       newSessionPersons.unshift(votedPerson);
     }
     for(let uvp=0; uvp<=unvotedPersons.length-1;uvp++){
       let unvotedPerson:Person = unvotedPersons[uvp];
       newSessionPersons.push(unvotedPerson);
     }

     if(voteCount==0){
      for(let pers of newSessionPersons){
        pers.visualLeft = false;
        pers.visualMiddle = false;
        pers.visualOnly = false;
        pers.visualRight = false;
      }
     }else {
      let index = 0;

      console.log(animationState);
      for(let pers of newSessionPersons){
        pers.visualLeft = false;
        pers.visualMiddle = false;
        pers.visualOnly = false;
        pers.visualRight = false;

        if(pers.visualVoted){
          pers.visualColor = animationState;
          switch (animationState){
            case "purple": {

              pers.blue = false;
              pers.green = false;
              pers.pink = false;
              pers.lightgreen = false;
              pers.orange = false;

              pers.purple = true;
              break;
            }
            case "green": {
              pers.blue = false;
              pers.pink = false;
              pers.lightgreen = false;
              pers.orange = false;
              pers.purple = false;
              pers.green = true;
              break;
            }
            case "pink": {
              pers.blue = false;
              pers.green = false;

              pers.lightgreen = false;
              pers.orange = false;
              pers.purple = false;
              pers.pink = true;
              break;
            }
            case "lightGreen": {
              pers.blue = false;
              pers.green = false;
              pers.pink = false;
              pers.orange = false;
              pers.purple = false;
              pers.lightgreen = true;
              break;
            }
            case "orange": {
              pers.blue = false;
              pers.green = false;
              pers.pink = false;
              pers.lightgreen = false;
              pers.purple = false;
              pers.orange = true;
              break;

            }
            case "blue": {
              pers.green = false;
              pers.pink = false;
              pers.lightgreen = false;
              pers.orange = false;
              pers.purple = false;
              pers.blue = true;
              break;
            }
          }
          if(voteCount ==1){
            pers.visualOnly = true;
          } else if(index ==0){
            pers.visualLeft = true;
          }else if(index == voteCount-1){
            pers.visualRight = true;
          }else{
            pers.visualMiddle = true;
          }
        }
        index++;
      }
     }

     this.timer = setTimeout(() => {
       for (let pers of session.sessionPersons){
        pers.visualLeft = false;
        pers.visualMiddle = false;
        pers.visualOnly = false;
        pers.visualRight = false;
        pers.visualColor = "inactive";
        pers.green = false;
        pers.pink = false;
        pers.lightgreen = false;
        pers.orange = false;
        pers.purple = false;
        pers.blue = false;
        
       }
       session.sessionPersons.sort(this.personCompare);
      }, 10000);
     session.sessionPersons = newSessionPersons;
   }


   public getProfileColor(){
     let person = this.getPerson(this.getCurrentUserID());
     if(typeof person === 'undefined'){
       return 2;
     }
    return person.color;
     /*
    for(let i=0;i<=this._persons.length-1;i++){
      let person: Person = this._persons[i];
      if(person.username==this.getCurrentUserID()){
        return person.color;
      }
    }
    */
   }

   getProfileColorString(){
    let colorNumber = this.getProfileColor().toString();

    switch(parseInt(colorNumber)){
      case 1: {
        return '#FF41D9';
      }
      case 2: {
        console.log('returning color 2');
        return '#FFB300';
      }
      case 3: {
        return '#CCFF00';
      }
      case 4: {
        return '#4cff00';
      }
      case 5: {
        return '#00CCFF';
      }
      case 6: {
        return '#D165FF';
      }
    }
  }

   public getCurrentUserID(){
    
     return this.sujjestAuthService.uid;
   }

   public setProfileColor(colorId){
    for(let i=0;i<=this._persons.length-1;i++){
      let person: Person = this._persons[i];
      if(person.username==this.getCurrentUserID()){
        person.color = colorId;
      }
    }
   }

   private downVoteOption(downVote: intVoteOption){
    let session: Session = this.findSession(downVote.sessionID);
    for(let dec of session.sessionDecisions){
      /*Clear finish color */
      //dec.animateFinish(0);
      if(dec.decisionId == downVote.decisionID){
        for(let opt of dec.options){
          if(opt.optionId == downVote.optionID){
            for(let pv of opt.profileVotes){
              if(pv.profileId == downVote.userID){
                opt.profileVotes = opt.profileVotes.filter(obj => obj !== pv);
                opt.voteCount--;
                if(session.checkUserVotedOption(this.getCurrentUserID(), dec.decisionId, opt.optionId)){                
                      opt.animateOption(this.getProfileColor());
                }else{
                  opt.animateOption(10);
                
                }
                this.orderSessionPersonsVote(opt);
              }
            }
          } else {
            opt.animationState = "inactive";
          }
        }
      }
    }
    session.setSessionUpdatedNow();
        if(typeof cordova !== "undefined"){       this.sortSessions()     }
   }

   private addMessage(addMsg: intAddMessage){
    let session: Session = this.findSession(addMsg.sessionID);
    let message: SujjestMessage = new SujjestMessage();
    message.created = Date();
    message.messageData = addMsg.messageData;
    message.messageId = addMsg.messageId;
    message.profileId = addMsg.userID;
    message.sessionId = addMsg.sessionID

    session.sessionMessages.push(message);
    session.setSessionUpdatedNow();
        if(typeof cordova !== "undefined"){       this.sortSessions()     }
   }

   private downFinish(upFin: intVoteFinish){
     //console.log('downFinish');
    let session: Session = this.findSession(upFin.sessionID);
    let decision = session.getDecision(upFin.decisionID);

    let test = Math.floor(Math.random()*6)+1;

    let dec = session.sessionDecisions[0];

    let finishVote = new FinishVote();
    finishVote.decisionId = decision.decisionId;
    finishVote.profileId = upFin.userID;
    finishVote.sessionId = upFin.sessionID;

    dec.finishVotes.push(finishVote);
    //console.log('finishDownVotePush');
    if(session.checkFinishVote(this.getCurrentUserID())){
      test = this.getProfileColor();
    }
    for( let animDec of session.sessionDecisions){
      animDec.animateFinish(test);
    }
    this.orderSessionPersonsFinish(finishVote.sessionId, test);


    
    /* Clear option colors */
    for(let dec of session.sessionDecisions){
      for(let opt of dec.options){
          opt.animationState = "inactive";
      }
    }
    //console.log(session);
    session.setSessionUpdatedNow();
        if(typeof cordova !== "undefined"){       this.sortSessions()     }

   }

   private upFinish(downFin: intVoteFinish){
    let session: Session = this.findSession(downFin.sessionID);
    let decision = session.getDecision(downFin.decisionID);

      let test = Math.floor(Math.random()*6)+1;

      let dec = session.sessionDecisions[0];
        
      for(let fv of dec.finishVotes){
        if(fv.profileId == downFin.userID){
          //console.log('fv found');
          dec.finishVotes = dec.finishVotes.filter(obj => obj !== fv);
          if(session.checkFinishVote(this.getCurrentUserID())){
            test = this.getProfileColor();
          }
          for( let animDec of session.sessionDecisions){
            animDec.animateFinish(test);
          }
          
          this.orderSessionPersonsFinish(downFin.sessionID, test);
        }
      }
      
      /* Clear option colors */
      for(let dec of session.sessionDecisions){
        for(let opt of dec.options){
            opt.animationState = "inactive";
        }
      }
      //console.log(session);

      session.setSessionUpdatedNow();
          if(typeof cordova !== "undefined"){       this.sortSessions()     }
   }

   private addPerson(addPers){
  //  console.log(addPers);


    if(addPers.userID == this.getCurrentUserID()){
      this.requestSessionUpdate(addPers.sessionID);
     }else{
   //    console.log('adding user to session!');
      let session: Session = this.findSession(addPers.sessionID);
      let personLookup = this.findPerson(addPers.userID)
      let person: Person = new Person();
      
      if(session.sessionDecisionFinished.length>0){
        person.active = false;
      }else{
        person.active = true;
      }

      if(!personLookup){
        person.email = addPers.userName;
        person.username = addPers.userID;
        person.firstname = addPers.firstName;
        person.lastname = addPers.lastName;
        person.color = 2;
        person.excluded = addPers.excluded;

        //dup check
        let dupFound = false;
        for(let i=0; i<=this._persons.length-1; i++){
          let dupPerson: Person = this._persons[i];
          if(dupPerson.email == person.email){
            dupFound = true;
          }
        }
        if(!dupFound){
          this._persons.push(person);
        }
        

      }else{
        person.username = personLookup.username;
        person.userimage = personLookup.userimage;
        person.firstname = personLookup.firstname;
        person.lastname = personLookup.lastname;
        person.color = personLookup.color;
        person.excluded = addPers.excluded;
      }

      if(session){
        session.sessionPersons.push(person);
        session.setSessionUpdatedNow();
            if(typeof cordova !== "undefined"){       this.sortSessions()     }
      }
      
     }
     
     this.requestSessionUpdate(addPers.sessionID);

   }

   public findSession(sesId: number){
    for(let ses of this._sessions){
       if(ses.session_id == sesId){
         let session: Session = ses;
         return session;
       }
     }
   }

   public findPerson(persId: string){
     for(let pers of this._persons){
       if(pers.username == persId){
         let person: Person = pers;
         return person;
       }
     }
   }
}

interface intKeepAlive{
  MsgType: string;
  userID: string;
}
interface intAddPerson{
  MsgType: string;
  userID: string;
  sessionID: number;
  userName: string;
  createdDate: string;
}

interface intVoteFinish{
  MsgType: string;
  userID: string;
  sessionID: number;
  decisionID: number;
}

interface intAddMessage{
  MsgType: string;
  userID: string;
  sessionID: number;
  messageData: string;
  messageId: number;
}

interface intVoteOption{
  MsgType: string;
  userID: string;
  sessionID: number;
  decisionID: number;
  optionID: number;
}

interface intAddOption{
  MsgType: string;
  userID: string;
  sessionID: number;
  decisionID: number;
  OptionTitle: string;
  optionID: number;
}

interface intRemoveUser{
  MsgType: string;
  userID: string;
  sessionID: number;
  inviteRemove: boolean;
}

interface intAddDecision{
  MsgType: string;
  userID: string;
  sessionID: number;
  DecisionName: string;
  decisionID: number;
}

interface intAddSession{
  MsgType: string;
  userID: string;
  sessionID: number;
  SessionName: string;
  decisionID: number;
}

interface intDecFinish{
  MsgType: string;
  userID: string;
  sessionID: number;
  decisionID: number;
  optionID: number;
}

interface intOptVoteReq{
  MsgType: string;
  userID: string;
  optionID: number;
  decisionID: number;
  sessionID: number;
}

interface intSesMesReq{
  MsgType: string;
  userID: string;
  sessionID: number;
  messageData: string;
}

interface intFinishReq{
  MsgType: string;
  userID: string;
  sessionID: number;
  decisionID: number;
}

interface intNewOptionReq{
  MsgType: string;
  userID: string;
  sessionID: number;
  decisionID: number;
  OptionTitle: string;
}

interface intSesProfileReq{
  MsgType: string;
  userID: string;
  sessionID: number;
  userName: string;
}

interface intNotificationRec{
  MsgType: string;
  userID: string;
  notificationID: number;
}

interface intNewSessionReq{
  MsgType: string;
  userID: string;
  sessionName: string;
}

interface intProfilesRequest{
  MsgType: string;
  userID: string;
}

interface intLeaveSesReq{
  MsgType: string;
  userID: string;
  sessionID: number;
}

interface intDecRequest {
  MsgType: string;
  userID: string;
  sessionID: number;
  DecisionName: string;
}

interface intDecFinishReq {
  MsgType: string;
  userID: string;
  sessionID: number;
  decisionID: number;
  optionID: number;
}

interface intColorReq{
  MsgType: string;
  userID: string;
  color: number;
}

interface testInterfaceSession {
  session_id: number;
  sessionName: string;
  createdBy: string;
  createdOn: any;
  sessionStatus: any;
  updatedOn: string;
}

interface intSesDump {
  MsgType: string;
  Sessions: Session[];
}

interface intProfDump {
  MsgType: string;
  Profiles: Person[];
}

interface intActiveUsers {
  MsgType: string;
  SessionID: number;
  users: Person[];
}

interface intSessionUpdate {
  MsgType: string;
  Session: Session;
}

interface intNotifcationUpdate {
  MsgType: string;
  userID: string;
}

interface MyRootObj {
  dump: testInterfaceSession[];
}

interface GenericSujjestMessage {
MsgType: string;
}

