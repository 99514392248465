import { Profilevote } from './profilevote';

export class Option {
    optionId: number;
    decisionId: number;
    sessionId: number;
    createdBy: string;
    title: string;
    voteCount: number;
    profileVotes: Profilevote[] = new Array();
    animationState: string;
    timer: any;

    highlight(colorNumber: number){
      switch(colorNumber) {
        case 1: {
          this.animationState = "pink";
          break;
        }
        case 2: {
          this.animationState = "orange";
          break;
        }
        case 3: {
          this.animationState = "lightGreen";
          break;
        }
        case 4: {
          this.animationState = "green";
          break;
        }
        case 5: {
          this.animationState = "blue";
          break;
        }
        case 6: {
          this.animationState = "purple";
          break;
        }
      }
    }

    removeHighlight(){
      this.animationState = "inactive";
    }

    animateOption(colorNumber: number){

            /*clearTimeout(this.timer);
            console.log("timeout cleared");*/

          let test = Math.floor(Math.random()*6)+1;
          if(colorNumber!=10 || 0){
            test=Math.floor(colorNumber);

          }

          switch(test) {
            case 1: {
              this.animationState = "pink";
              this.timer = setTimeout(() => this.animationState = "inactive", 10000);
              break;
            }
            case 2: {
              this.animationState = "orange";
              this.timer = setTimeout(() => this.animationState = "inactive", 10000);
              
              break;
            }
            case 3: {
              this.animationState = "lightGreen";
              this.timer = setTimeout(() => this.animationState = "inactive", 10000);
      
              break;
            }
            case 4: {
              this.animationState = "green";
              this.timer = setTimeout(() => this.animationState = "inactive", 10000);
      
              break;
            }
            case 5: {
              this.animationState = "blue";
              this.timer = setTimeout(() => this.animationState = "inactive", 10000);
      
              break;
            }
            case 6: {
      
              this.animationState = "purple";
              this.timer = setTimeout(() => this.animationState = "inactive", 10000);
      
              break;
            }
          }


    }
}
