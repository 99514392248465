import { Option } from './option';
import { FinishVote } from './finish-vote'
import { Profilevote } from './profilevote';


export class Decision {
    decisionId: number;
    decisionName: string;
    sessionId: number;
    createdBy: string;
    options: Option[] = new Array();
    finishVotes: FinishVote[] = new Array();
    finishAnimationState: string;
    timer: any;
    finishingActiveUserIds: String[] = new Array();
    finishingFinishVotes: FinishVote[] = new Array(); 
    preventVoteMessage = false;

    animateFinish(colorNumber: number){
        
        /*clearTimeout(this.timer);
        console.log("timeout cleared");*/
        let test = Math.floor(Math.random()*6)+1;
        if(colorNumber!=10 || 0){
          test=Math.floor(colorNumber);

        }
      
      switch(test) {
        case 0: {
          this.finishAnimationState = "inactive";
          break;
        }
        case 1: {
          this.finishAnimationState = "pink";
          
          break;
        }
        case 2: {
          this.finishAnimationState = "orange";
          break;
        }
        case 3: {
          this.finishAnimationState = "lightGreen";
          break;
        }
        case 4: {
          this.finishAnimationState = "green";
          break;
        }
        case 5: {
          this.finishAnimationState = "blue";
          
  
          break;
        }
        case 6: {
          this.finishAnimationState = "purple";
          break;
        }

      }
      this.timer = setTimeout(() => this.finishAnimationState = "inactive", 10000);
}

public checkUserActiveFinishing(userId){
  for(let actUsr = 0; actUsr<=this.finishingActiveUserIds.length-1;actUsr++){
    let activeUserId = this.finishingActiveUserIds[actUsr];
      if(activeUserId == userId){
          return true;
      }
  }
  return false;
}

public getOption(optionId){
  for(let opt=0;opt<=this.options.length-1;opt++){
    let option: Option = this.options[opt];
    if(option.optionId==optionId){
      return option;
    }
  }
}

public checkOptionVoted(optionId, profileId){
  let option = this.getOption(optionId);
  for(let pv=0; pv<=option.profileVotes.length-1;pv++){
    let profileVote: Profilevote = option.profileVotes[pv];
    if(profileVote.profileId==profileId){
      return true;
    }
  }
  return false;
}

public checkFinishVoted(profileId){
  for(let fv=0; fv<=this.finishVotes.length-1;fv++){
    let finishVote: FinishVote = this.finishVotes[fv];
    if (finishVote.profileId==profileId){
      return true;
    }
  }
  return false;
}

}
