import { Routes } from '@angular/router';
const ɵ0 = () => import("./start-director/start-director.module.ngfactory").then(m => m.StartDirectorModuleNgFactory), ɵ1 = () => import("./onboarding/onboarding.module.ngfactory").then(m => m.OnboardingModuleNgFactory), ɵ2 = () => import("./sujjest-login/sujjest-login.module.ngfactory").then(m => m.SujjestLoginModuleNgFactory), ɵ3 = () => import("./index/index.module.ngfactory").then(m => m.IndexModuleNgFactory), ɵ4 = () => import("./notification-center/notification-center.module.ngfactory").then(m => m.NotificationCenterModuleNgFactory), ɵ5 = () => import("./anon-session/anon-session.module.ngfactory").then(m => m.AnonSessionModuleNgFactory), ɵ6 = () => import("./profile/profile.module.ngfactory").then(m => m.ProfileModuleNgFactory), ɵ7 = () => import("./session/session.module.ngfactory").then(m => m.SessionModuleNgFactory), ɵ8 = () => import("./anon-session/anon-session.module.ngfactory").then(m => m.AnonSessionModuleNgFactory), ɵ9 = () => import("./anon-session/anon-session.module.ngfactory").then(m => m.AnonSessionModuleNgFactory), ɵ10 = () => import("./anon-session/anon-session.module.ngfactory").then(m => m.AnonSessionModuleNgFactory), ɵ11 = () => import("./colorwheel/colorwheel.module.ngfactory").then(m => m.ColorwheelModuleNgFactory), ɵ12 = () => import("./sujjest-login/sujjest-login.module.ngfactory").then(m => m.SujjestLoginModuleNgFactory);
const routes = [
    { path: '', loadChildren: ɵ0 },
    { path: 'onboard', loadChildren: ɵ1 },
    { path: 'login', loadChildren: ɵ2 },
    { path: 'index', loadChildren: ɵ3 },
    { path: 'notifications', loadChildren: ɵ4 },
    { path: 'Public', loadChildren: ɵ5 },
    { path: 'profile', loadChildren: ɵ6 },
    { path: 'session', loadChildren: ɵ7 },
    { path: 'AnonSession', loadChildren: ɵ8 },
    { path: 'Lunch', loadChildren: ɵ9 },
    { path: 'SessionInvite', loadChildren: ɵ10 },
    { path: 'colorpick', loadChildren: ɵ11 },
    { path: '**', loadChildren: ɵ12 },
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12 };
