import { Injectable, Inject } from '@angular/core';
import { FirebaseApp } from 'angularfire2';
import * as firebase from 'firebase/app';
import 'firebase/storage';
import {Upload} from '../potos/upload';
import { AuthService } from '../auth.service';

@Injectable()
export class FileUploadService {

  uId: string;

  constructor(@Inject(FirebaseApp) firebaseApp: any,@Inject(AuthService) authService: AuthService) {
    this.uId = authService.afAuth.auth.currentUser.uid;
   }
  private basePath:string = '/uploads';
  

  
  pushUpload(upload: Upload) {
    let storageRef = firebase.storage().ref();

    let uploadTask = storageRef.child(this.uId+ '/profile.jpg').put(upload.file);
    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot) =>  {
        // upload in progress

      },
      (error) => {
        // upload failed
        //console.log(error)
      },
      () => {
        // upload success
        upload.url = uploadTask.snapshot.downloadURL
        upload.name = upload.file.name

      }
    );
  }
}
