import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { AngularFireModule } from 'angularfire2';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { AngularFireMessaging } from 'angularfire2/messaging';
import { AngularFireStorageModule } from 'angularfire2/storage';
import { FormsModule } from '@angular/forms';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { SujjestSessionadapterService } from './sujjest-sessionadapter.service';
import { AuthService } from './auth.service';
import { AppRoutingModule } from './/app-routing.module';

import { SujjestWebsocketService } from './sujjest-websocket.service';
import { TimeAgoPipe } from 'time-ago-pipe';

import { FileUploadService } from './services/fileupload.service';
import { ClickOutsideModule } from 'ng4-click-outside';
import { TextareaAutosizeModule } from 'ngx-textarea-autosize';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';

import { ServiceWorkerModule } from '@angular/service-worker';
import { Ng2ImgMaxModule } from 'ng2-img-max';
import { MessagingService } from './messaging.service';
import { NgxPicaModule } from '@digitalascetic/ngx-pica';
import { NgxLinkifyjsModule } from 'ngx-linkifyjs';
import { ContentLoaderModule } from '@netbasal/ngx-content-loader';
import { RouterModule } from '@angular/router';







@NgModule({
  declarations: [
    AppComponent,
    TimeAgoPipe,
  ],
  imports: [
    BrowserModule,
    Ng2ImgMaxModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireStorageModule,
    AngularFireAuthModule,
    FormsModule,
    AppRoutingModule,

    ClickOutsideModule,
    TextareaAutosizeModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    HttpClientJsonpModule,
    NgxPicaModule,
    NgxLinkifyjsModule.forRoot(
      {
        enableHash: false, // optional - default true
        enableMention: false // optional - default true
      }),
    ContentLoaderModule,
    RouterModule.forRoot([]),



  ],
  providers: [ SujjestSessionadapterService, AuthService,  MessagingService, SujjestWebsocketService, FileUploadService, AngularFireMessaging ],
  bootstrap: [AppComponent]
})
export class AppModule { }
