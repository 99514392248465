export class NotificationItem {
    notificationID: number;
    notificationType: number;
    receiverID: string;
    extraString: string;
    received: boolean;
    originatorID: string;
    sessionID: number;
    extraInt: number;
    createdOn: any;
    image: any;
    menuOpen: boolean;
}
